import * as React from 'react';

import classes from './index.scss';
import * as colors from 'libs/colors';
import { fmtNum } from 'libs/formatter';
import { useIsSsr } from 'libs/useIsSsr';
import { GiftIcon, FilterIcon, TagIcon, UserIcon } from 'components/icons';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import Divider from 'components/atoms/Divider';
import Paragraph from 'components/atoms/Paragraph';
import Text from 'components/atoms/Text';
import Button from 'components/atoms/Button';

import { SearchConditions, emptySearchConditions } from 'components/combi-search/libs/search';
import type { Scene, Relationship, SelectedCategory, PriceRange, Detail } from 'components/combi-search/types';
import { gtmClassName } from 'components/combi-search/libs/gtm';

import ModalSwitcher, { ModalType } from './ModalSwitcher';

export type Props = {
  fetchItemCount: (selected: SearchConditions) => Promise<number>;
  onSubmit: (cond: SearchConditions) => void;
};

const CombiSearchForm: React.FC<Props> = ({ fetchItemCount, onSubmit }) => {
  const [selected, setSelected] = React.useState<SearchConditions>(emptySearchConditions);

  const [modal, setModal] = React.useState<ModalType | null>(null);
  const closeModal = React.useCallback(() => {
    setModal(null);
  }, []);

  const [itemCount, setItemCount] = React.useState<number | null>(null);

  React.useEffect(() => {
    fetchItemCount(selected)
      .then(setItemCount)
      .catch(() => setItemCount(null));
  }, [selected, fetchItemCount]);

  const onSubmitClick = React.useCallback(() => {
    onSubmit(selected);
  }, [selected, onSubmit]);

  const isSsr = useIsSsr();

  return (
    <>
      <Box bg={colors.white} padding="12px 16px 0 16px">
        <Box className={classes['shadow-box']} radius={9} border={colors.gray20} bg={colors.white}>
          <Box horizontal align="center">
            <SceneInput selected={selected.scene} onClick={() => setModal('scene-relationship')} />
            <Divider vertical length="19px" />
            <RelationshipInput selected={selected.relationship} onClick={() => setModal('scene-relationship')} />
          </Box>
          <Divider />
          <CategoryInput selected={selected.category} onClick={() => setModal('category')} />
          <Divider />
          <PriceRangeInput selected={selected.priceRange} onClick={() => setModal('price-range')} />
          <Divider />
          <DetailInput selected={selected.detail} onClick={() => setModal('detail')} />
          <Divider />
          <Box padding="16px">
            <Paragraph align="center" color={colors.gray50} bold>
              <Text size="12px">該当する商品数：</Text>
              <Text size="16px">{itemCount !== null ? fmtNum(itemCount) : '-'}</Text>
              <Text size="12px">件</Text>
            </Paragraph>
          </Box>
          <Box padding="0 16px 16px 16px">
            <Button bg={colors.softOrange} onClick={onSubmitClick}>
              <Text size="15px" color={colors.white} bold>
                プレゼントを探す
              </Text>
            </Button>
          </Box>
        </Box>
      </Box>

      {/* 事前ビルド時にはレンダリングしない */}
      {isSsr ? null : <ModalSwitcher modal={modal} onClose={closeModal} onSelect={setSelected} />}
    </>
  );
};

type SceneInputProps = {
  selected: Scene | null;
  onClick: () => void;
};

const SceneInput: React.FC<SceneInputProps> = ({ selected, onClick }) => (
  <Box horizontal padding="16px" align="center" onClick={onClick} className={gtmClassName.scene}>
    <GiftIcon size="16px" color={iconColor(selected !== null)} />
    <Spacer size={8} />
    <Paragraph maxLines={1} align="left" size="14px" color={textColor(selected !== null)}>
      {selected?.name ?? 'シーン'}
    </Paragraph>
  </Box>
);

type RelationshipInputProps = {
  selected: Relationship | null;
  onClick: () => void;
};

const RelationshipInput: React.FC<RelationshipInputProps> = ({ selected, onClick }) => (
  <Box horizontal padding="16px" align="center" onClick={onClick} className={gtmClassName.relationship}>
    <UserIcon size="16px" color={iconColor(selected !== null)} />
    <Spacer size={8} />
    <Paragraph maxLines={1} align="left" size="14px" color={textColor(selected !== null)}>
      {selected?.name ?? '関係性'}
    </Paragraph>
  </Box>
);

type CategoryInputProps = {
  selected: SelectedCategory | null;
  onClick: () => void;
};

const CategoryInput: React.FC<CategoryInputProps> = ({ selected, onClick }) => {
  let text = '';
  if (selected?.parent.name) {
    text += selected.parent.name;
  }
  if (selected?.category?.name) {
    text += `・${selected.category.name}`;
  }
  if (selected?.productGroup?.name) {
    text += `・${selected.productGroup.name}`;
  }
  if (selected === null) {
    text = 'カテゴリ';
  }
  return (
    <Box horizontal padding="16px" align="center" onClick={onClick} className={gtmClassName.category}>
      <TagIcon size="16px" color={iconColor(selected !== null)} />
      <Spacer size={8} />
      <Paragraph maxLines={1} align="left" size="14px" color={textColor(selected !== null)}>
        {text}
      </Paragraph>
    </Box>
  );
};

type PriceRangeInputProps = {
  selected: PriceRange;
  onClick: () => void;
};

const PriceRangeInput: React.FC<PriceRangeInputProps> = ({ selected, onClick }) => {
  const isActive = selected.lower !== 0 || selected.upper !== null;
  const text = React.useMemo(() => {
    const lower = fmtNum(selected.lower);
    const upper = selected.upper ? fmtNum(selected.upper) : '上限なし';
    return `${lower} ~ ${upper}`;
  }, [selected]);

  return (
    <Box horizontal padding="16px" align="baseline" onClick={onClick} className={gtmClassName.priceRange}>
      <Spacer size={2} />
      <Paragraph align="left" size="16px" color={iconColor(isActive)}>
        &yen;
      </Paragraph>
      <Spacer size={12} />
      <Paragraph maxLines={1} align="left" size="14px" color={textColor(isActive)}>
        {text}
      </Paragraph>
    </Box>
  );
};

type DetailInputProps = {
  selected: Detail;
  onClick: () => void;
};

const DetailInput: React.FC<DetailInputProps> = ({ selected, onClick }) => {
  const textItems = [];
  if (selected.keyword) {
    textItems.push(`"${selected.keyword}"`);
  }
  if (selected.delivery) {
    const date = selected.delivery.date;
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    textItems.push(`〜${y}/${m}/${d}`);
  }
  if (selected.age) {
    textItems.push(selected.age.name);
  }
  selected.subOptionCategories.forEach((opt) => {
    textItems.push(opt.name);
  });
  selected.coupons.forEach((coupon) => {
    textItems.push(coupon.name);
  });
  if (selected.includeOutOfStock) {
    textItems.push('在庫切れ商品も表示');
  }
  if (selected.isTanpProducts) {
    textItems.push('タンプからの発送商品のみ表示');
  }
  if (selected.onlyIncludeEGiftProducts) {
    textItems.push('eギフト対象商品のみ表示');
  }

  const text = textItems.join('、') || 'こだわり';

  const isActive = textItems.length > 0;

  return (
    <Box horizontal padding="16px" align="center" onClick={onClick} className={gtmClassName.detail}>
      <FilterIcon size="16px" color={iconColor(isActive)} />
      <Spacer size={8} />
      <Paragraph maxLines={1} align="left" size="14px" color={textColor(isActive)}>
        {text}
      </Paragraph>
    </Box>
  );
};

const iconColor = (isActive: boolean): colors.Color => (isActive ? colors.softOrange : colors.gray30);

const textColor = (isActive: boolean): colors.Color => (isActive ? colors.gray90 : colors.gray50);

export default CombiSearchForm;
