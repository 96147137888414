import * as React from 'react';

import classes from './index.scss';
import * as colors from 'libs/colors';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import Paragraph from 'components/atoms/Paragraph';

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
require('smoothscroll-polyfill').polyfill();

type Props = {
  tabs: string[];
  selected: string;
  onSelect: (tab: string) => void;
};

export const TabSelect: React.FC<Props> = React.memo(({ tabs, selected, onSelect }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const scrollToElement = React.useCallback((elem: HTMLElement | null) => {
    if (elem && containerRef.current) {
      // 選択中のタブと,その左側のタブがある程度
      // 表示されるような位置にスクロール
      const left = elem.offsetLeft - 50;
      containerRef.current.scrollTo({
        left,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div ref={containerRef} className={classes['scroll-container']}>
      <Box horizontal>
        <Spacer size={8} />
        {tabs.map((tab) => (
          <React.Fragment key={tab}>
            <TabItem ref={tab === selected ? scrollToElement : null} name={tab} selected={tab === selected} onSelect={onSelect} />
            <Spacer size={8} />
          </React.Fragment>
        ))}
      </Box>
    </div>
  );
});

TabSelect.displayName = 'TabSelect';

/*
 * ==========
 * TabItem
 * ==========
 */
type TabItemProps = {
  name: string;
  selected: boolean;
  onSelect: (name: string) => void;
};

const TabItem = React.memo(
  React.forwardRef<HTMLDivElement, TabItemProps>(({ name, selected, onSelect }, ref) => (
    <Box ref={ref} padding="6px 8px" radius={20} bg={tabBgColor(selected)} onClick={() => onSelect(name)}>
      <Paragraph nowrap align="center" size="12px" color={tabColor(selected)} bold={selected}>
        {name}
      </Paragraph>
    </Box>
  ))
);

TabItem.displayName = 'TabItem';

const tabColor = (selected: boolean): colors.Color => (selected ? colors.white : colors.gray50);

const tabBgColor = (selected: boolean): colors.Color => (selected ? colors.softOrange : colors.white);
