import * as React from 'react';

import { gray10, gray50 } from 'libs/colors';
import Paragraph from 'components/atoms/Paragraph';
import Box from 'components/atoms/Box';

export type Props = {
  name: string;
};

const SelectorHeadline: React.FC<Props> = React.memo(({ name }) => (
  <Box padding="16px 16px 8px 16px" bg={gray10}>
    <Paragraph align="left" size="12px" color={gray50} bold>
      {name}
    </Paragraph>
  </Box>
));

SelectorHeadline.displayName = 'SelectorHeadline';

export default SelectorHeadline;
