import * as React from 'react';

// 子孫コンポーネントで `var(--vh)` を使えるようにする
const ViewHeightProvider: React.FC = React.memo(({ children }) => {
  const [vh, setVh] = React.useState('1vh');

  // ブラウザの初期表示の状態がステータスバー表示状態なので、
  // その時のステータスバーなどを含まないViewの高さを取得する
  // 初期値として window.innerHeight を設定したいが、事前ビルド時には window が未定義になるので、
  // ここでの初期値には null を設定しておき、後続の処理でハンドリングする
  const [viewHeight, setViewHeight] = React.useState<number | null>(null);

  const lastInnerHeight = React.useRef<number | null>(null);

  React.useEffect(() => {
    setViewHeight(window.innerHeight);
    // Safariの上部にユニバーサルリンクのアプリバナーが表示される場合、マウント時の viewHeight だとバナー領域の高さを含まない値になってしまうため、
    // window.innerHeight の変更を監視して、その最小値を viewHeight として使うようにする
    const handler = () => {
      if (lastInnerHeight.current !== null && Math.abs(lastInnerHeight.current - window.innerHeight) > 60) {
        setViewHeight(window.innerHeight);
      }
      lastInnerHeight.current = window.innerHeight;
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  React.useEffect(() => {
    if (viewHeight !== null) {
      // innerHeightはアドレスバーを含めたview height
      setVh(`${viewHeight * 0.01}px`);
    }
  }, [viewHeight]);

  const style = React.useMemo(
    () => ({
      ['--vh' as any]: vh,
    }),
    [vh]
  );

  return <div style={style}>{children}</div>;
});

ViewHeightProvider.displayName = 'ViewHeightProvider';

export default ViewHeightProvider;
