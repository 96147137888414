import * as React from 'react';
import classes from './Button.scss';
import { Color } from 'libs/colors';

export type Props = {
  bg: Color;
  border?: Color;
  shadow?: boolean;
  onClick: () => void;
};

const Button: React.FC<Props> = React.memo(({ bg, border, shadow, onClick, children }) => {
  const classNames = [classes['button']];
  if (shadow) classNames.push(classes['shadow']);
  const className = classNames.join(' ');

  const style = React.useMemo(
    () => ({
      backgroundColor: bg.hex,
      border: border ? `1px solid ${border.hex}` : 'none',
    }),
    [bg, border]
  );

  return (
    <button onClick={onClick} className={className} style={style}>
      {children}
    </button>
  );
});
Button.displayName = 'Button';

export default Button;
