import * as React from 'react';

import classes from './Select.scss';
import * as colors from 'libs/colors';
import { merge } from 'libs/classname';
import { CarretDownIcon } from 'components/icons';

export type Props = {
  items: { key: string; value: string }[];
  selected: string | null;
  onSelect: (selected: string) => void;
  className?: string;
};

const Select: React.FC<Props> = React.memo(({ items, selected, onSelect, className }) => {
  const options = React.useMemo(
    () =>
      items.map((item) => (
        <option key={item.key} value={item.key}>
          {item.value}
        </option>
      )),
    [items]
  );

  return (
    <div className={merge(classes['container'], className)}>
      <select className={classes['select']} value={selected || undefined} onChange={(e) => onSelect(e.target.value)}>
        {options}
      </select>
      <CarretDownIcon className={classes['icon']} color={colors.gray90} size="32px" fill strokeWidth="0" />
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
