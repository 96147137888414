export const gtmClassName = {
  scene: 'gtmSearchFormSceneClick',
  relationship: 'gtmSearchFormRelationshipClick',
  category: 'gtmSearchFormCategoryClick',
  detail: 'gtmSearchFormDetailClick',
  priceRange: 'gtmSearchFormPriceRangeClick',
  small: {
    scene: 'gtmHeaderSearchFormSceneClick',
    relationship: 'gtmHeaderSearchFormRelationshipClick',
    category: 'gtmHeaderSearchFormCategoryClick',
    detail: 'gtmHeaderSearchFormDetailClick',
    priceRange: 'gtmHeaderSearchFormPriceRangeClick',
  },
};
