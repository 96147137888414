import * as React from 'react';

import type { CombiSearchData } from 'components/combi-search/types';
import { SearchConditions, searchConditionToURL, queryToString, fetchItemCount } from 'components/combi-search/libs/search';
import ViewHeightProvider from 'components/molecules/ViewHeightProvider';
import { MountedTimeContextProvider } from 'libs/useMountedTime';
import { CombiSearchContextProvider } from 'components/combi-search/Context';
import CombiSearchForm from './CombiSearchForm';
import { loadDataFromDom } from 'components/combi-search/libs/loadDataFromDom';

const PreBuildableCombiSearchForm: React.FC = () => {
  const [combiSearchData, setCombiSearchData] = React.useState<CombiSearchData | null>(null);

  React.useEffect(() => {
    setCombiSearchData(loadDataFromDom('top-page-data'));
  }, []);

  const onSubmit = React.useCallback((cond: SearchConditions) => {
    const { path, query } = searchConditionToURL(cond);
    window.location.href = path + queryToString(query);
  }, []);

  return (
    <ViewHeightProvider>
      <MountedTimeContextProvider>
        <CombiSearchContextProvider value={combiSearchData}>
          <CombiSearchForm fetchItemCount={fetchItemCount} onSubmit={onSubmit} />
        </CombiSearchContextProvider>
      </MountedTimeContextProvider>
    </ViewHeightProvider>
  );
};

export default PreBuildableCombiSearchForm;
