import type { ParentCategory, ProductGroup } from 'components/combi-search/types';
import type { Ranking, CategoryBasedBrandList } from '../types';

export const loadData = (
  dataDomId: string
): {
  sceneBasedRankings: Ranking[];
  categoryBasedRankings: Ranking[];
  categoryBasedBrands: CategoryBasedBrandList[];
  isUserSignIn: boolean;
} => {
  const dataDom = document.getElementById(dataDomId);
  if (!dataDom) throw new Error(`data dom ${dataDomId} is not found`);

  const dataText = dataDom.textContent || '';
  const rawData = JSON.parse(dataText) as RawData;

  const sceneBasedRankings = rawData.sceneBasedRankings.map((ranking) => parseSceneBasedRanking(ranking, rawData.parents, rawData.favoritedProductIds));

  const categoryBasedRankings = rawData.categoryBasedRankings.map((ranking) => parseCategoryBasedRanking(ranking, rawData.parents, rawData.favoritedProductIds));

  const categoryBasedBrands = rawData.categoryBasedBrands.map((brands) => parseCategoryBasedBrands(brands, rawData.parents));

  return {
    sceneBasedRankings,
    categoryBasedRankings,
    categoryBasedBrands,
    isUserSignIn: rawData.isUserSignIn,
  };
};

type RawData = {
  parents: ParentCategory[];
  sceneBasedRankings: RawSceneBasedRanking[];
  categoryBasedRankings: RawCategoryBasedRanking[];
  categoryBasedBrands: RawCategoryBasedBrandList[];
  favoritedProductIds: number[];
  isUserSignIn: boolean;
};

export type RawSceneBasedRanking = {
  name: string;
  sceneEngName: string;
  relationshipEngName: string | null;
  products: Product[];
};

export type RawCategoryBasedRanking = {
  parentEngName: string;
  products: Product[];
};

export type Product = {
  id: number;
  name: string;
  brandName: string | null;
  imageUrl: string;
  productGroupEngName: string | null;
  price: number;
  isFavorited?: boolean;
};

export type RawCategoryBasedBrandList = {
  parentEngName: string;
  brands: {
    id: number;
    name: string;
    imageUrl: string | null;
  }[];
};

const parseSceneBasedRanking = (ranking: RawSceneBasedRanking, parents: ParentCategory[], favoritedProductIds: number[]): Ranking => {
  let link = `/products/scene-${ranking.sceneEngName}`;
  if (ranking.relationshipEngName) {
    link += `/relationship-${ranking.relationshipEngName}`;
  }
  return {
    name: ranking.name,
    link,
    products: ranking.products.map((product) => ({
      ...product,
      productGroupName: product.productGroupEngName ? findProductGroup(parents, product.productGroupEngName)?.name ?? null : null,
      isFavorited: favoritedProductIds.includes(product.id),
    })),
  };
};

const parseCategoryBasedRanking = (ranking: RawCategoryBasedRanking, parents: ParentCategory[], favoritedProductIds: number[]): Ranking => ({
  name: parents.find((p) => p.engName === ranking.parentEngName)!.name,
  link: `/products/parent-${ranking.parentEngName}`,
  products: ranking.products.map((product) => ({
    ...product,
    productGroupName: product.productGroupEngName ? findProductGroup(parents, product.productGroupEngName)?.name ?? null : null,
    isFavorited: favoritedProductIds.includes(product.id),
  })),
});

const parseCategoryBasedBrands = (brands: RawCategoryBasedBrandList, parents: ParentCategory[]): CategoryBasedBrandList => ({
  category: parents.find((p) => p.engName === brands.parentEngName)!.name,
  brands: brands.brands,
});

const findProductGroup = (parents: ParentCategory[], searchEngName: string): ProductGroup | null => {
  for (const parent of parents) {
    for (const category of parent.categories) {
      for (const product of category.productGroups) {
        if (product.engName === searchEngName) {
          return product;
        }
      }
    }
  }

  // eslint-disable-next-line no-console
  console.warn(`"${searchEngName}" is not found in passed data.`);

  return null;
};
