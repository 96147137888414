import * as React from 'react';
import { Color } from 'libs/colors';

export type Props = {
  size?: string;
  color?: Color;
  bold?: boolean;
};

const Text: React.FC<Props> = React.memo(({ size, color, bold, children }) => {
  const style: React.CSSProperties = {};
  if (size !== undefined) style.fontSize = size;
  if (color !== undefined) style.color = color.hex;
  if (bold) style.fontWeight = 700;

  return <span style={style}>{children}</span>;
});
Text.displayName = 'Text';

export default Text;
