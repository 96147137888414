import * as React from 'react';

import * as colors from 'libs/colors';
import Box from 'components/atoms/Box';

import cs from './index.scss';
import Header from './Header';
import Calendar from './Calendar';
import Footer from '../Footer';
import { CombiSearchContext } from 'components/combi-search/Context';
import { Prefecture, DeliveryCondition, DeliveryDate } from 'components/combi-search/types';

type Props = {
  selected: DeliveryDate | null;
  now: Date;
  onSelect: (selected: DeliveryDate | null) => void;
};

// NOTE
// PreBuild時にはdeliveryConditionsなどが空配列に
// なることがあるので注意すること
const DeliverySelector: React.FC<Props> = React.memo(({ selected, now, onSelect }) => {
  const { deliveryConditions, preciousDays, hasteShippingTime } = React.useContext(CombiSearchContext);

  // 初期値はnull. 後でちゃんとした値を入れる
  const [selectedDeliveryCondition, setSelectedDeliveryCondition] = React.useState<DeliveryCondition | null>(null);

  // 初期値はnull. 後でちゃんとした値を入れる
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  // 外部による更新
  React.useEffect(() => {
    if (selected) {
      // 選択されている都道府県があれば、
      // そのdeliveryConditionを反映させる
      const cond = deliveryConditions.find((cond) => cond.prefecture.id === selected.prefecture.id);
      if (cond) {
        setSelectedDeliveryCondition(cond);
      }

      // selectedDateの設定
      setSelectedDate(selected.date);
    } else {
      // 選択されている都道府県がなければ、
      // Tokyoを探してそれを初期値に設定する
      const tokyo = deliveryConditions.find((cond) => cond.prefecture.id === 13);
      if (tokyo) {
        setSelectedDeliveryCondition(tokyo);
      }
    }
  }, [deliveryConditions, selected]);

  const prefectures = React.useMemo(() => deliveryConditions.map((cond) => cond.prefecture), [deliveryConditions]);

  // ユーザーによってselectedDeliveryConditionが
  // 変更されるとき、selectedDateも変更する.
  // 逆に、システムによってselectedDeliveryCondition
  // が変更されるとき、selectedDateは変更しない.
  // (deliveryConditionsが更新されたときなど)
  const onUserSelectPrefecture = React.useCallback(
    (prefecture: Prefecture) => {
      // selectedDeliveryConditionの設定
      const cond = deliveryConditions.find((cond) => cond.prefecture.id === prefecture.id)!; // never undefined
      setSelectedDeliveryCondition(cond);

      // selectedDateの設定
      const hasteShippingDate = getHasteShippingDate(cond, hasteShippingTime, now);
      const minDeliveryDate = hasteShippingDate ?? cond.minDeliveryDate;
      setSelectedDate(minDeliveryDate);
    },
    [deliveryConditions, hasteShippingTime, now]
  );

  // hasteShippingDateの設定
  const hasteShippingDate = selectedDeliveryCondition ? getHasteShippingDate(selectedDeliveryCondition, hasteShippingTime, now) : null;

  // 最短お届け日
  const minDeliveryDate = hasteShippingDate ?? selectedDeliveryCondition?.minDeliveryDate ?? FAKE_DATE;

  // お急ぎ便の終了時間
  const hasteShippingEndHour = getHasteShippingEndHour(hasteShippingTime, now);

  return (
    <Box bg={colors.white}>
      <Header className={cs['header']} prefectures={prefectures} selectedPrefecture={selectedDeliveryCondition?.prefecture ?? null} onSelectPrefecture={onUserSelectPrefecture} hasteShippingEndHour={hasteShippingEndHour} />
      <Calendar now={now} minSelectableDate={minDeliveryDate} hasteShippingDate={hasteShippingDate} preciousDays={preciousDays} selected={selectedDate} onSelect={setSelectedDate} />
      <Footer
        submitButtonText="保存"
        clearButtonText="指定しない"
        opaque
        onClear={React.useCallback(() => {
          onSelect(null);
        }, [onSelect])}
        onSubmit={React.useCallback(() => {
          if (selectedDeliveryCondition && selectedDate) {
            onSelect({
              prefecture: selectedDeliveryCondition.prefecture,
              date: selectedDate,
            });
          } else {
            onSelect(null);
          }
        }, [selectedDeliveryCondition, selectedDate, onSelect])}
      />
    </Box>
  );
});

DeliverySelector.displayName = 'DeliverySelector';

const FAKE_DATE = new Date(0);

const getHasteShippingEndHour = (hasteShippingTime: { start: Date; end: Date }, now: Date): number | null => {
  if (hasteShippingTime.start.valueOf() < now.valueOf() && now.valueOf() < hasteShippingTime.end.valueOf()) {
    return hasteShippingTime.end.getHours();
  } else {
    return null;
  }
};

const getHasteShippingDate = (deliveryCondition: DeliveryCondition, hasteShippingTime: { start: Date; end: Date }, now: Date): Date | null => {
  if (
    // お急ぎ便対応時間内
    hasteShippingTime.start.valueOf() < now.valueOf() &&
    now.valueOf() < hasteShippingTime.end.valueOf() &&
    // お届け先がお急ぎ便対応時間内
    deliveryCondition.hasteShippingDate !== null &&
    // お急ぎ便が通常便より早い
    deliveryCondition.hasteShippingDate.valueOf() < deliveryCondition.minDeliveryDate.valueOf()
  ) {
    return deliveryCondition.hasteShippingDate;
  } else {
    return null;
  }
};

export default DeliverySelector;
