import * as React from 'react';

import * as color from 'libs/colors';
import { fmtPrice } from 'libs/formatter';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import Paragraph from 'components/atoms/Paragraph';
import Text from 'components/atoms/Text';
import Divider from 'components/atoms/Divider';
import DualSlider, { Range } from 'components/atoms/DualSlider';
import TopBar from 'components/molecules/TopBar';

import Footer from '../Footer';
import { PriceRange } from 'components/combi-search/types';

export type Props = {
  maxPrice: number;
  priceRange: PriceRange;
  onChange: (range: PriceRange) => void;

  // 1. onTouchEnd時
  // 2. クリアボタン押下時
  // に呼ばれる
  onChangeEnd?: () => void;
  onSubmit: () => void;
  submitButtonText: string;
};

const PriceRangeSelector: React.FC<Props> = React.memo(({ maxPrice, priceRange, onChange, onChangeEnd, onSubmit, submitButtonText }) => {
  const onSliderChange = React.useCallback(
    (range: Range) => {
      onChange({
        lower: range.lower,
        upper: range.upper === maxPrice ? null : range.upper,
      });
    },
    [onChange, maxPrice]
  );

  const onClear = React.useCallback(() => {
    onChange({ lower: 0, upper: null });
    if (onChangeEnd) {
      onChangeEnd();
    }
  }, [onChange, onChangeEnd]);

  const lowerText = fmtPrice(priceRange.lower);
  const upperText = priceRange.upper === null ? '上限なし' : fmtPrice(priceRange.upper);

  return (
    <Box bg={color.white}>
      <TopBar>
        <Paragraph align="center" size="18px" bold color={color.black}>
          価格帯
        </Paragraph>
      </TopBar>
      <Divider />
      <Spacer size={32} />
      <Box horizontal align="center">
        <Spacer size={32} />
        <Box padding="8px 12px" border={color.gray30} radius={8}>
          <Text size="12px" color={color.gray30}>
            最低料金
          </Text>
          <Spacer size={4} />
          <Text size="16px" color={color.black}>
            {lowerText}
          </Text>
        </Box>
        <Spacer size={16} />
        <Divider length="32px" bg={color.gray30} />
        <Spacer size={16} />
        <Box padding="8px 12px" border={color.gray30} radius={8}>
          <Text size="12px" color={color.gray30}>
            最高料金
          </Text>
          <Spacer size={4} />
          <Text size="16px" color={color.black}>
            {upperText}
          </Text>
        </Box>
        <Spacer size={32} />
      </Box>
      <Spacer size={16} />
      <Box padding="0 16px">
        <DualSlider
          value={{
            lower: priceRange.lower,
            upper: priceRange.upper ?? maxPrice,
          }}
          onChange={onSliderChange}
          onTouchEnd={onChangeEnd ?? emptyFunction}
          min={0}
          max={maxPrice}
          step={1000}
        />
      </Box>
      <Spacer size={16} />
      <Footer submitButtonText={submitButtonText} clearButtonText="クリア" onClear={onClear} onSubmit={onSubmit} />
    </Box>
  );
});

PriceRangeSelector.displayName = 'PriceRangeSelector';

const emptyFunction = () => undefined;

export default PriceRangeSelector;
