import * as React from 'react';

import useBodyScrollLock from 'libs/useBodyScrollLock';
import SwipeDownModal, { ContentContainer } from 'components/molecules/SwipeDownModal';

import PriceRangeSelector from 'components/combi-search/sp/components/PriceRangeSelector';
import type { PriceRange } from 'components/combi-search/types';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (priceRange: PriceRange) => void;
  zIndex: number;
};

const PriceRangeSelectModal: React.FC<Props> = React.memo(({ show, onClose, onSelect, zIndex }) => {
  const [selected, setSelected] = React.useState<PriceRange>({
    lower: 0,
    upper: null,
  });

  const contentContainerRef = React.useRef<HTMLDivElement | null>(null);

  useBodyScrollLock(show);

  return (
    <SwipeDownModal visible={show} zIndex={zIndex} onClose={onClose} contentContainerRef={contentContainerRef}>
      <ContentContainer ref={contentContainerRef}>
        <PriceRangeSelector maxPrice={30000} priceRange={selected} onChange={setSelected} onSubmit={() => onSelect(selected)} submitButtonText="保存する" />
      </ContentContainer>
    </SwipeDownModal>
  );
});

PriceRangeSelectModal.displayName = 'PriceRangeSelectModal';

export default PriceRangeSelectModal;
