import * as React from 'react';

import * as color from 'libs/colors';
import { genPublicUrl } from 'libs/cloudinary';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import LazyImage from 'components/atoms/LazyImage';
import Paragraph from 'components/atoms/Paragraph';
import { ChevronRightIcon } from 'components/icons';

import { CategoryBasedBrandList } from '../types';
import { LabeledCarousel } from '../components/LabeledCarousel';
import { Section } from '../components/Section';

export type Props = {
  brandLists: CategoryBasedBrandList[];
};

const CategoryBasedBrandsSection: React.FC<Props> = ({ brandLists }) => (
  <Section title="ブランドから探す">
    <LabeledCarousel
      pages={brandLists.map(({ category, brands }) => ({
        label: category,
        element: <CarouselPage category={category} brands={brands} />,
      }))}
    />
  </Section>
);

/*
 * ============
 * CarouselPage
 * ============
 */
type CarouselPageProps = {
  category: string;
  brands: {
    id: number;
    name: string;
    imageUrl: string | null;
  }[];
};

const CarouselPage: React.FC<CarouselPageProps> = ({ category, brands }) => (
  <div>
    <Box horizontal wrap space="evenly">
      {brands.map(({ id, name, imageUrl }) => (
        <BrandItem key={`${category}-${id}`} id={id} name={name} imageUrl={imageUrl} />
      ))}
    </Box>
    <Spacer size={16} />
    <a href="/brands">
      <Box horizontal padding="0 20px" align="stretch">
        <Paragraph full align="right" color={color.softOrange} size="15px">
          ブランド一覧
        </Paragraph>
        <Spacer size={15} />
        <ChevronRightIcon color={color.softOrange} />
      </Box>
    </a>
  </div>
);

/*
 * ============
 * BrandItem
 * ============
 */
type BrandItemProps = {
  id: number;
  name: string;
  imageUrl: string | null;
};

const BrandItem: React.FC<BrandItemProps> = ({ id, name, imageUrl }) => (
  <a href={`/brands/view/${id}`}>
    <Box padding="16px 0 0 0" width={BrandItemWidth}>
      {imageUrl ? <LazyImage src={genPublicUrl(imageUrl, 200)} width={BrandItemWidth} height={BrandItemWidth} fit="contain" /> : <Spacer size={BrandItemWidth} />}
      <Spacer size={8} />
      <Paragraph full align="center" maxLines={2} size="12px" color={color.black}>
        {name}
      </Paragraph>
    </Box>
  </a>
);

const BrandItemWidth = '28vw';

export default CategoryBasedBrandsSection;
