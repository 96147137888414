import * as React from 'react';
import classes from './TopBar.scss';

// 中央にcenter alignされたコンテンツ（ページタイトルなど）があり、
// 左右に何らかのitemがあるようなコンポーネント
export const TopBar: React.FC = ({ children }) => <div className={classes['container']}>{children}</div>;

export const LeftItems: React.FC = ({ children }) => <div className={classes['left-items']}>{children}</div>;

export const RightItems: React.FC = ({ children }) => <div className={classes['right-items']}>{children}</div>;

export default TopBar;
