import * as React from 'react';
import * as color from 'libs/colors';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import Paragraph from 'components/atoms/Paragraph';

export type Props = {
  title: string;
};

export const Section: React.FC<Props> = ({ title, children }) => (
  <Box bg={color.white}>
    <Spacer size={24} />
    <Paragraph full align="center" size="18px" color={color.black} bold>
      {title}
    </Paragraph>
    <Spacer size={16} />
    {children}
  </Box>
);
