import * as React from 'react';

import { merge } from 'libs/classname';

import { PreciousDay, PreciousEvent } from 'components/combi-search/types';
import cs from './Calendar.scss';

type Props = {
  now: Date;
  minSelectableDate: Date;
  hasteShippingDate: Date | null;
  preciousDays: PreciousDay[];
  selected: Date | null;
  onSelect: (date: Date) => void;
};

const Calendar: React.FC<Props> = React.memo(({ now, minSelectableDate, hasteShippingDate, preciousDays, selected, onSelect }) => {
  const [expandedPreciousDay, setExpandedPreciousDay] = React.useState<Date | null>(null);

  const months = React.useMemo(() => next7Month(now), [now]);

  const onCloseExpandedPreciousEvents = React.useCallback(() => {
    setExpandedPreciousDay(null);
  }, []);

  return (
    <div
      className={cs['calendar']}
      onClick={React.useCallback(() => {
        setExpandedPreciousDay(null);
      }, [])}
    >
      {months.map(({ year, month, dates }) => (
        <div key={`${year}${month}`} className={cs['monthly-calendar']}>
          <p className={cs['monthly-calendar-title']}>
            {year}年 {month}月
          </p>
          <div className={cs['cell-container']}>{dates.map((date, i) => (date ? <DateCell key={i} date={date} annotation={date.valueOf() === hasteShippingDate?.valueOf()} disabled={date.valueOf() < minSelectableDate.valueOf()} preciousEvents={preciousDays.filter((pd) => pd.month === month && pd.day === date.getDate()).map(({ event }) => event)} onClickEventBubble={setExpandedPreciousDay} expandPreciousEvents={date.valueOf() === expandedPreciousDay?.valueOf()} onCloseExpandedPreciousEvents={onCloseExpandedPreciousEvents} selected={date.valueOf() === selected?.valueOf()} onSelect={onSelect} /> : <EmptyCell key={i} />))}</div>
        </div>
      ))}
    </div>
  );
});

Calendar.displayName = 'Calendar';

// 直近7ヶ月分（今月を含む）の年と月を返す
const next7Month = (
  now: Date
): {
  year: number;
  month: number;
  dates: (Date | null)[];
}[] =>
  Array(7)
    .fill(0)
    .map((_, i) => {
      const nMonthsAfter = new Date(now.getFullYear(), now.getMonth() + i);
      const year = nMonthsAfter.getFullYear();
      const month = nMonthsAfter.getMonth() + 1;
      const dates = datesOfMonth(year, month);
      return {
        year,
        month,
        dates,
      };
    });

// その月の日曜から土曜までの日のリストを返す.
// 日付が存在しない場合はnullを返す.
// month: 1 ~ 12
const datesOfMonth = (year: number, month: number): (Date | null)[] => {
  // - その月の開始日
  // - 日曜から最初の日までを埋めるnull配列
  //   - e.x. firstDateが日曜なら長さ0, 土曜なら6
  const firstDate = new Date(year, month - 1, 1);
  const startPadding = Array(firstDate.getDay()).fill(null);

  // - その月の最終日
  //   - 月を翌月、日を0にすることで取得できる
  // - 最終日から土曜までの日数
  const lastDate = new Date(year, month, 0);
  const endPadding = Array(6 - lastDate.getDay()).fill(null);

  // その月に存在する日の配列
  const dates = Array(lastDate.getDate())
    .fill(null)
    .map((_, i) => new Date(year, month - 1, i + 1));

  return startPadding.concat(dates).concat(endPadding);
};

/*
 * ===========
 * DateCell
 * ===========
 */
type DateCellProps = {
  date: Date;
  disabled: boolean;
  annotation: boolean;
  preciousEvents: PreciousEvent[];
  onClickEventBubble: (date: Date) => void;
  expandPreciousEvents: boolean;
  onCloseExpandedPreciousEvents: () => void;
  selected: boolean;
  onSelect: (date: Date) => void;
};

const DateCell: React.FC<DateCellProps> = React.memo(({ date, disabled, annotation, preciousEvents, onClickEventBubble, expandPreciousEvents, onCloseExpandedPreciousEvents, selected, onSelect }) => (
  <div className={cs['cell']}>
    <p
      className={merge(cs['date-text'], selected ? cs['selected'] : '', disabled ? cs['disabled'] : '')}
      onClick={React.useCallback(() => {
        if (!disabled) {
          onSelect(date);
        }
      }, [date, disabled, onSelect])}
    >
      {date.getDate()}
      {annotation ? <div className={cs['annotation']}>※</div> : null}
    </p>
    {preciousEvents.length > 0 ? <EventBubble events={preciousEvents} onClick={() => onClickEventBubble(date)} /> : null}
    {expandPreciousEvents ? <ExpandedEventList date={date} events={preciousEvents} onClose={onCloseExpandedPreciousEvents} /> : null}
  </div>
));

DateCell.displayName = 'DateCell';

/*
 * ============
 * EventBubble（吹き出し）
 * ============
 */
type EventBubbleProps = {
  // length > 0
  events: PreciousEvent[];
  onClick: () => void;
};

const EventBubble: React.FC<EventBubbleProps> = React.memo(({ events, onClick }) => (
  <div
    className={cs['bubble']}
    onClick={React.useCallback(
      (e) => {
        e.stopPropagation();
        onClick();
      },
      [onClick]
    )}
  >
    <p className={merge(cs['bubble-text'], events.length > 1 || events[0].type === 'user' ? cs['underline'] : null, events.length > 1 ? cs['soft-orange'] : colorClassOfEvent(events[0]))}>{bubbleText(events)}</p>
  </div>
));

EventBubble.displayName = 'EventBubble';

// イベントのカラー（EventBubbleで表示する色）を返す
const colorClassOfEvent = (event: PreciousEvent): string => {
  if (event.type === 'user') {
    return cs['soft-orange'];
  }
  if (event.engName === 'christmas') {
    return cs['dark-red'];
  }
  return cs['gray80'];
};

const bubbleText = (events: PreciousEvent[]): React.ReactNode => {
  if (events.length > 1) {
    return (
      <>
        {events.length}つの
        <br />
        イベント
      </>
    );
  }

  const event = events[0];
  if (event.type === 'user') {
    return event.personName;
  } else {
    return event.eventName;
  }
};

/*
 * ==================
 * ExpandedEventList
 * ==================
 */
type ExpandedEventListProps = {
  date: Date;
  events: PreciousEvent[];
  onClose: () => void;
};

const MapOfDayAndCss = [cs['left'], cs['left'], cs['center'], cs['center'], cs['center'], cs['right'], cs['right']];

const ExpandedEventList: React.FC<ExpandedEventListProps> = React.memo(({ date, events, onClose }) => (
  <div
    className={merge(cs['expanded-event-list'], MapOfDayAndCss[date.getDay()])}
    onClick={React.useCallback((e) => {
      e.stopPropagation();
    }, [])}
  >
    <div className={cs['close-btn']} onClick={onClose}>
      <div className={cs['close-btn-content']} />
    </div>
    {events.map((event, i) => (
      <React.Fragment key={i}>
        {i > 0 && <div className={cs['divider']} />}
        <div className={cs['expanded-event-item']}>
          <img className={cs['image']} src={event.imageUrl} />
          <div className={cs['event-name-box']}>
            {event.type === 'user' && <p className={cs['text']}>{event.personName}</p>}
            <p className={cs['text']}>{event.eventName}</p>
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
));

ExpandedEventList.displayName = 'ExpandedEventList';

/*
 * ==========
 * EmptyCell
 * ==========
 */
const EmptyCell = () => <div className={cs['cell']} />;

export default Calendar;
