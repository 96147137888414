import * as React from 'react';
import ReactDOM from 'react-dom';

import { runAfterDomLoad } from 'libs/runAfterDomLoad';
import PreBuildableCombiSearchForm from 'components/combi-search/sp/top-page/PreBuildableCombiSearchForm';
import { loadData } from 'components/rankings/sp/libs/loadDataFromDom';
import { RankingSection } from 'components/rankings/sp/RankingSection';
import CategoryBasedBrandsSection from 'components/rankings/sp/CategoryBasedBrandsSection';

// DOMの読み込みが終わった後に実行する
runAfterDomLoad(() => {
  // 検索フォームのレンダリング
  ReactDOM.hydrate(<PreBuildableCombiSearchForm />, document.getElementById('react_combi_search_form'));

  const { sceneBasedRankings, categoryBasedRankings, categoryBasedBrands, isUserSignIn } = loadData('top-page-data');

  // シーン別プレゼントランキングのレンダリング
  ReactDOM.render(<RankingSection title="シーン別プレゼントランキング" rankings={sceneBasedRankings} isUserSignIn={isUserSignIn} />, document.getElementById('scene-based-ranking'));

  // カテゴリ別プレゼントランキングのレンダリング
  ReactDOM.render(<RankingSection title="カテゴリ別プレゼントランキング" rankings={categoryBasedRankings} isUserSignIn={isUserSignIn} />, document.getElementById('category-based-ranking'));

  // ブランドから探すセクションのレンダリング
  ReactDOM.render(<CategoryBasedBrandsSection brandLists={categoryBasedBrands} />, document.getElementById('search-by-brand'));
});
