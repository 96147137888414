import * as React from 'react';

import * as color from 'libs/colors';
import { genPublicUrl } from 'libs/cloudinary';
import Paragraph from 'components/atoms/Paragraph';
import Divider from 'components/atoms/Divider';
import Box, { BoxItem } from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import LazyImage from 'components/atoms/LazyImage';
import TopBar from 'components/molecules/TopBar';

import cs from './index.scss';
import { CombiSearchContext } from 'components/combi-search/Context';
import SelectorHeadline from '../SelectorHeadline';
import Footer from '../Footer';
import { Scene, Relationship } from 'components/combi-search/types';

export type Props = {
  selectedScene: Scene | null;
  selectedRelationship: Relationship | null;
  onSelect: (scene: Scene | null, relationship: Relationship | null) => void;
  onSubmit: () => void;
  submitButtonText: string;
};

const SceneRelationshipSelector: React.FC<Props> = React.memo(({ selectedScene, selectedRelationship, onSelect, onSubmit, submitButtonText }) => {
  const { scenes, relationships } = React.useContext(CombiSearchContext);

  // scenes1は上段に表示するもの
  // scenes2は下段に表示するもの
  const [scenes1, scenes2] = React.useMemo(() => [scenes.filter((_, i) => i % 2 === 0), scenes.filter((_, i) => i % 2 === 1)], [scenes]);

  // relationships1は上段に表示するもの
  // relationships2は下段に表示するもの
  const [relationships1, relationships2] = React.useMemo(() => {
    // 選択可能なRelationshipの一覧
    const selectable = selectedScene ? relationships.filter((r) => selectedScene.relationshipCodes.includes(r.engName)) : relationships;
    return [selectable.filter((_, i) => i % 2 === 0), selectable.filter((_, i) => i % 2 === 1)];
  }, [selectedScene, relationships]);

  const onSelectScene = React.useCallback(
    (selected: Scene) => {
      // Sceneが新しく選択された時、その時に選択中のRelationshipが
      // 引き続き選択可能かチェックする。
      if (selectedRelationship && !selected.relationshipCodes.includes(selectedRelationship.engName)) {
        onSelect(selected, null);
      } else {
        onSelect(selected, selectedRelationship);
      }
    },
    [selectedRelationship, onSelect]
  );

  const onSelectRelationship = React.useCallback(
    (selected: Relationship) => {
      onSelect(selectedScene, selected);
    },
    [selectedScene, onSelect]
  );

  const onClear = React.useCallback(() => {
    onSelect(null, null);
  }, [onSelect]);

  return (
    <Box bg={color.white}>
      <TopBar>
        <Paragraph align="center" size="18px" color={color.black} bold>
          シーン・関係性
        </Paragraph>
      </TopBar>
      <Divider />
      <SelectorHeadline name="シーン" />
      <Divider />
      <Box bg={color.white}>
        <Spacer size={16} />
        <ItemsRowWindow>
          <ItemsRow items={scenes1} selected={selectedScene} onSelect={onSelectScene} />
          <Spacer size={12} />
          <ItemsRow items={scenes2} selected={selectedScene} onSelect={onSelectScene} />
          <Spacer size={8} />
        </ItemsRowWindow>
        <Spacer size={6} />
      </Box>
      <Divider />
      <SelectorHeadline name="関係性" />
      <Divider />
      <Box bg={color.white}>
        <Spacer size={16} />
        <ItemsRowWindow>
          <ItemsRow items={relationships1} selected={selectedRelationship} onSelect={onSelectRelationship} />
          <Spacer size={12} />
          <ItemsRow items={relationships2} selected={selectedRelationship} onSelect={onSelectRelationship} />
          <Spacer size={8} />
        </ItemsRowWindow>
        <Spacer size={6} />
      </Box>
      <Footer submitButtonText={submitButtonText} clearButtonText="クリア" onClear={onClear} onSubmit={onSubmit} />
    </Box>
  );
});

SceneRelationshipSelector.displayName = 'SceneRelationshipSelector';

const ItemsRowWindow: React.FC = ({ children }) => {
  const [firstTouchY, setFirstTouchY] = React.useState(0);
  const [scrollable, setScrollable] = React.useState(true);

  const onTouchStart = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setFirstTouchY(e.touches[0].pageY);
  }, []);

  const onTouchMove = React.useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      // 100px以上タッチを下げないと、
      // モーダルを動かさないようにする
      const currentTouchY = e.touches[0].pageY;
      if (currentTouchY - firstTouchY < 100) {
        e.stopPropagation();
      } else {
        setScrollable(false);
      }
    },
    [firstTouchY]
  );

  const onTouchEnd = React.useCallback(() => {
    setScrollable(true);
  }, []);

  const classNames = [cs['items-row-window']];
  if (!scrollable) classNames.push(cs['unscroll']);
  const className = classNames.join(' ');

  return (
    <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className={className}>
      {children}
    </div>
  );
};

type Item = {
  name: string;
  imageUrl: string;
};

type ItemsRowProps<T> = {
  items: T[];
  selected: T | null;
  onSelect: (item: T) => void;
};

const ItemsRow = <T extends Item>({ items, selected, onSelect }: ItemsRowProps<T>) => (
  <Box horizontal>
    <Spacer size={16} />
    {items.map((item, i) => (
      <React.Fragment key={item.name}>
        {i !== 0 && <Spacer size={12} />}
        <BoxItem>
          <ItemCell item={item} selected={item.name === selected?.name} onSelected={() => onSelect(item)} />
        </BoxItem>
      </React.Fragment>
    ))}
    <Spacer size={16} />
  </Box>
);

/*
 * ==========
 * ItemCell
 * ==========
 */
export type ItemCellProps = {
  item: Item;
  selected: boolean;
  onSelected: () => void;
};

const ItemCell: React.FC<ItemCellProps> = React.memo(({ item, selected, onSelected }) => {
  const classNames = [cs['image-container']];
  if (selected) classNames.push(cs['selected']);
  const className = classNames.join(' ');

  return (
    <Box width="56px" onClick={onSelected}>
      <div className={className}>
        <LazyImage src={genPublicUrl(item.imageUrl, 50 * 4)} width="50px" height="50px" fit="cover" />
      </div>
      <Spacer size={8} />
      <Paragraph align="center" full size="12px" color={selected ? color.softOrange : color.gray90}>
        {item.name}
      </Paragraph>
    </Box>
  );
});

ItemCell.displayName = 'ItemCell';

export default SceneRelationshipSelector;
