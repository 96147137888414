import * as React from 'react';
import classes from './HorizontalViewSwitcher.scss';

export type Props = {
  currentViewIdx: number;
  height?: string;
  smooth?: boolean;
  contentContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const HorizontalViewSwitcher: React.FC<Props> = React.memo(({ children, currentViewIdx, height, smooth, contentContainerRef }) => (
  <div className={classes['window']}>
    <div
      className={[classes['child-view-container'], smooth ? classes['smooth'] : ''].join(' ')}
      style={{
        ['--view-idx' as any]: currentViewIdx,
      }}
    >
      {toNodeArray(children).map((node, i) => (
        <div
          className={classes['child-view']}
          key={i}
          style={{
            ['--height' as any]: height,
          }}
          ref={i === currentViewIdx ? contentContainerRef : null}
        >
          {node}
        </div>
      ))}
    </div>
  </div>
));
HorizontalViewSwitcher.displayName = 'HorizontalViewSwitcher';

const toNodeArray = (node: React.ReactNode): React.ReactNode[] => (isReactNodeArray(node) ? node : [node]);

const isReactNodeArray = (node: React.ReactNode): node is React.ReactNode[] => Array.isArray(node);

export default HorizontalViewSwitcher;
