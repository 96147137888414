import * as React from 'react';

/*
  コンポーネントがマウントされたときの時刻を取得するHooks
  マウントされていない時はnullを返す
  事前ビルドに関わるコンポーネントでの使用を想定する
  テスト時に制御できるよう引数でマウント時刻を渡せるようになっている
*/

const MountedTimeContext = React.createContext<Date | null>(null);

type MountedTimeContextProviderProps = {
  value?: Date;
};

export const MountedTimeContextProvider: React.FC<MountedTimeContextProviderProps> = ({ value, children }) => {
  const [mountedTime, setMountedTime] = React.useState<Date | null>(value ?? null);

  React.useEffect(() => {
    setMountedTime((prev) => prev ?? new Date());
  }, []);

  return <MountedTimeContext.Provider value={mountedTime}>{children}</MountedTimeContext.Provider>;
};

const useMountedTime = (): Date | null => React.useContext(MountedTimeContext);

export default useMountedTime;
