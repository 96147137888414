import * as React from 'react';

import * as color from 'libs/colors';
import { fmtPrice } from 'libs/formatter';
import { genPublicUrl } from 'libs/cloudinary';
import { ChevronRightIcon } from 'components/icons';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import Paragraph from 'components/atoms/Paragraph';
import LazyImage from 'components/atoms/LazyImage';
import { Ranking, RankingProduct } from '../types';
import { Section } from '../components/Section';
import { LabeledCarousel } from '../components/LabeledCarousel';
import classes from './index.scss';

export type Props = {
  title: string;
  rankings: Ranking[];
  mockFavButton?: boolean;
  isUserSignIn?: boolean;
};

export const RankingSection: React.FC<Props> = ({ title, rankings, mockFavButton, isUserSignIn }) => (
  <Section title={title}>
    <LabeledCarousel
      pages={rankings.map((ranking) => ({
        label: ranking.name,
        element: <SingleRankingPage ranking={ranking} mockFavButton={mockFavButton} isUserSignIn={isUserSignIn} />,
      }))}
    />
  </Section>
);

/*
 * ===========
 * SingleRankingPage
 * ===========
 */
type SingleRankingPageProps = {
  ranking: Ranking;
  mockFavButton?: boolean;
  isUserSignIn?: boolean;
};

export const SingleRankingPage: React.FC<SingleRankingPageProps> = ({ ranking, mockFavButton, isUserSignIn }) => (
  <div>
    <Box horizontal wrap space="between">
      {ranking.products.map((product, i) => (
        <RankingItem key={`${ranking.name}-${i}`} rank={i + 1} product={product} mockFavButton={mockFavButton} isUserSignIn={isUserSignIn} />
      ))}
    </Box>
    <Spacer size={15} />
    <a href={ranking.link} className={classes['ranking-link']}>
      <Box horizontal padding="0 20px" align="stretch">
        <Paragraph full align="right" color={color.softOrange} size="15px">
          {ranking.name}をもっと見る
        </Paragraph>
        <Spacer size={15} />
        <ChevronRightIcon color={color.softOrange} />
      </Box>
    </a>
  </div>
);

/*
 * ===========
 * RankingItem
 * ===========
 */
type RankingItemProps = {
  rank: number;
  product: RankingProduct;
  mockFavButton?: boolean;
  isUserSignIn?: boolean;
};

const RankingItem: React.FC<RankingItemProps> = ({ rank, product, mockFavButton, isUserSignIn }) => (
  <a href={`/products/view/${product.id}`} className={classes['ranking-item-container']}>
    <p className={classes['ranking-badge']}>{rank}</p>
    <LazyImage src={genPublicUrl(product.imageUrl, 500)} width={RankingItemWidth} height={RankingItemWidth} fit="cover" />
    <Box padding="8px 8px 16px">
      {product.brandName ? (
        <Paragraph full align="left" maxLines={1} size="10px" color={color.gray50}>
          {product.brandName}
        </Paragraph>
      ) : (
        <Spacer size={14} />
      )}
      <Spacer size={2} />
      <Paragraph full align="left" maxLines={1} size="10px" color={color.gray90} bold>
        {product.name}
      </Paragraph>
      <Spacer size={2} />
      {product.productGroupName ? (
        <Paragraph full align="left" maxLines={1} size="10px" color={color.gray50}>
          {product.productGroupName}
        </Paragraph>
      ) : (
        <Spacer size={14} />
      )}
      <Spacer size={4} />
      <div className={classes['price-fav-button-container']}>
        <Paragraph full align="left" maxLines={1} size="16px" color={color.gray90}>
          {fmtPrice(product.price)}
        </Paragraph>
        <fav-button product-id={product.id} favorited={product.isFavorited} mock={mockFavButton ?? false} show-modal-on-click={!isUserSignIn} device="sp" log-add-fav-event="add_favorite_from_product_list" log-delete-fav-event="delete_favorite_from_product_list" />
      </div>
    </Box>
  </a>
);

// 各Item間のマージンが1pxで、1行に3つのItemが入る
const RankingItemWidth = 'calc((100vw - 1px * 2) / 3)';
