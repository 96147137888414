import * as React from 'react';

import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import { Carousel } from 'components/molecules/Carousel';
import { TabSelect } from './TabSelect';

type Props = {
  pages: {
    label: string;
    element: React.ReactElement;
  }[];
};

export const LabeledCarousel: React.FC<Props> = ({ pages }) => {
  const [currentIdx, setCurrentIdx] = React.useState(0);
  const tabs = React.useMemo(() => pages.map((item) => item.label), [pages]);

  return (
    <Box>
      <TabSelect tabs={tabs} selected={tabs[currentIdx]} onSelect={(tab) => setCurrentIdx(tabs.indexOf(tab))} />
      <Spacer size={16} />
      <Carousel idx={currentIdx} onChange={setCurrentIdx}>
        {pages.map((item) => (
          <React.Fragment key={item.label}>{item.element}</React.Fragment>
        ))}
      </Carousel>
    </Box>
  );
};
