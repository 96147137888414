import * as React from 'react';

import { SearchConditions } from 'components/combi-search/libs/search';
import BlackFilter from 'components/combi-search/sp/components/BlackFilter';

import SceneRelationshipSelectModal from '../SceneRelationshipSelectModal';
import CategorySelectModal from '../CategorySelectModal';
import DetailSelectModal from '../DetailSelectModal';
import PriceRangeSelectModal from '../PriceRangeSelectModal';

export type Props = {
  modal: ModalType | null;
  onClose: () => void;
  onSelect: (updator: (prev: SearchConditions) => SearchConditions) => void;
};

export type ModalType = 'scene-relationship' | 'category' | 'price-range' | 'detail';

const ModalSwitcher: React.FC<Props> = ({ modal, onClose, onSelect }) => (
  <>
    <BlackFilter visible={modal !== null} onClick={onClose} />

    {/* シーン・関係性選択モーダル */}
    <SceneRelationshipSelectModal
      show={modal === 'scene-relationship'}
      onClose={onClose}
      onSelect={React.useCallback(
        (scene, relationship) => {
          onSelect((selected) => ({ ...selected, scene, relationship }));
          onClose();
        },
        [onSelect, onClose]
      )}
      zIndex={10}
    />

    {/* カテゴリ選択モーダル */}
    <CategorySelectModal
      show={modal === 'category'}
      onClose={onClose}
      onSelect={React.useCallback(
        (category) => {
          onSelect((selected) => ({ ...selected, category }));
          onClose();
        },
        [onSelect, onClose]
      )}
      zIndex={10}
    />

    {/* こだわり選択モーダル */}
    <DetailSelectModal
      show={modal === 'detail'}
      onClose={onClose}
      onSelect={React.useCallback(
        (detail) => {
          onSelect((selected) => ({ ...selected, detail }));
          onClose();
        },
        [onSelect, onClose]
      )}
      zIndex={10}
    />

    {/* 価格選択モーダル */}
    <PriceRangeSelectModal
      show={modal === 'price-range'}
      onClose={onClose}
      onSelect={React.useCallback(
        (priceRange) => {
          onSelect((selected) => ({ ...selected, priceRange }));
          onClose();
        },
        [onSelect, onClose]
      )}
      zIndex={10}
    />
  </>
);

export default ModalSwitcher;
