import * as React from 'react';
import classes from './index.scss';

export type Props = {
  value: Range;
  onChange: (range: Range) => void;
  onTouchEnd: () => void;
  min: number;
  max: number;
  step: number;
};

export type Range = {
  lower: number;
  upper: number;
};

const DualSlider: React.FC<Props> = React.memo(({ value: { lower, upper }, onChange, onTouchEnd, min, max, step }) => (
  <div className={classes['dual-slider']}>
    <input
      className={classes['slider']}
      type="range"
      min={min}
      max={max}
      step={step}
      value={lower}
      onChange={React.useCallback(
        (e) => {
          // upperより上にいかないようにする
          const maxLower = upper - (max - min) / 10;
          const val = Math.min(parseInt(e.target.value), maxLower);
          if (val != lower) {
            onChange({
              lower: val,
              upper,
            });
          }
        },
        [lower, upper, max, min, onChange]
      )}
      onTouchMove={React.useCallback((e) => e.stopPropagation(), [])}
      onTouchEnd={onTouchEnd}
      style={React.useMemo(
        () => ({
          ['--bg-lower' as any]: `${(lower / (max - min)) * 100}%`,
          ['--bg-upper' as any]: `${(upper / (max - min)) * 100}%`,
        }),
        [lower, upper, max, min]
      )}
    />
    <input
      className={classes['slider']}
      type="range"
      min={min}
      max={max}
      step={step}
      value={upper}
      onChange={React.useCallback(
        (e) => {
          // lowerより下にいかないようにする
          const minUpper = lower + (max - min) / 10;
          const val = Math.max(parseInt(e.target.value), minUpper);
          if (val != upper) {
            onChange({
              lower,
              upper: val,
            });
          }
        },
        [lower, upper, max, min, onChange]
      )}
      onTouchMove={React.useCallback((e) => e.stopPropagation(), [])}
      onTouchEnd={onTouchEnd}
    />
  </div>
));

DualSlider.displayName = 'DualSlider';

export default DualSlider;
