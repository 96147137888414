import * as React from 'react';

import * as color from 'libs/colors';
import { ChevronRightIcon, ChevronLeftIcon } from 'components/icons';
import Paragraph from 'components/atoms/Paragraph';
import Text from 'components/atoms/Text';
import Divider from 'components/atoms/Divider';
import Spacer from 'components/atoms/Spacer';
import Box, { BoxItem } from 'components/atoms/Box';
import TopBar, { LeftItems } from 'components/molecules/TopBar';
import { Age } from 'components/combi-search/types';
import { availableAges } from 'components/combi-search/libs/ages';

export type Props = {
  selected: Age | null;
  onSelect: (selected: Age | null) => void;
  onClose: () => void;
};

const AgeSelector: React.FC<Props> = ({ selected, onSelect, onClose }) => (
  <div>
    <TopBar>
      <LeftItems>
        <Box horizontal padding="18px" align="center" onClick={onClose}>
          <ChevronLeftIcon color={color.gray50} />
          <Spacer size={5} />
          <Text size="15px" color={color.gray50}>
            戻る
          </Text>
        </Box>
      </LeftItems>
      <Paragraph align="center" size="18px" bold color={color.black}>
        年代
      </Paragraph>
    </TopBar>
    <Divider />
    {selected && (
      <>
        <Box padding="18px 19px" bg={color.gray10}>
          <Text size="12px" color={color.softOrange}>
            {selected.name}
          </Text>
          <Text size="12px" color={color.gray50}>
            {' '}
            を選択中
          </Text>
        </Box>
        <Divider />
      </>
    )}
    <Box horizontal align="center" padding="16px 21px 16px 16px" bg={color.white} onClick={() => onSelect(null)}>
      <BoxItem scale={1}>
        <Text size="15px" color={color.black}>
          指定なし
        </Text>
      </BoxItem>
      <ChevronRightIcon color={color.gray50} />
    </Box>
    <Divider />
    {availableAges.map((age) => (
      <React.Fragment key={age.name}>
        <Box horizontal align="center" padding="16px 21px 16px 16px" bg={color.white} onClick={() => onSelect(age)}>
          <BoxItem scale={1}>
            <Text size="15px" color={color.black}>
              {age.name}
            </Text>
          </BoxItem>
          <ChevronRightIcon color={color.gray50} />
        </Box>
        <Divider />
      </React.Fragment>
    ))}
  </div>
);

export default AgeSelector;
