import * as React from 'react';

import useBodyScrollLock from 'libs/useBodyScrollLock';
import SwipeDownModal, { ContentContainer } from 'components/molecules/SwipeDownModal';
import CategorySelector from 'components/combi-search/sp/components/CategorySelector';
import type { SelectedCategory } from 'components/combi-search/types';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (category: SelectedCategory | null) => void;
  zIndex: number;
};

const CategorySelectModal: React.FC<Props> = React.memo(({ show, onClose, onSelect, zIndex }) => {
  const [selected, setSelected] = React.useState<SelectedCategory | null>(null);

  const contentContainerRef = React.useRef<HTMLDivElement | null>(null);

  const onCategorySelect = React.useCallback(
    (category) => {
      setSelected(category);
      onSelect(category);
    },
    [onSelect]
  );

  useBodyScrollLock(show);

  return (
    <SwipeDownModal visible={show} zIndex={zIndex} onClose={onClose} contentContainerRef={contentContainerRef}>
      <ContentContainer ref={contentContainerRef} full>
        <CategorySelector selected={selected} onSelect={onCategorySelect} />
      </ContentContainer>
    </SwipeDownModal>
  );
});

CategorySelectModal.displayName = 'CategorySelectModal';

export default CategorySelectModal;
