import * as React from 'react';

const useBodyScrollLock = (lock: boolean): void => {
  React.useEffect(() => {
    if (lock) {
      const html = window.document.documentElement;
      const scrollTop = html.scrollTop;

      // 背景コンテンツをスクロールさせない処理
      // styleを一気に適用させるためにrequestAnimationFrameを利用する
      window.requestAnimationFrame(() => {
        html.style.position = 'fixed';
        html.style.overflow = 'hidden';
        html.style.width = '100%';
        html.style.top = `-${scrollTop}px`;
      });

      return () => {
        // 背景コンテンツを元の位置に戻す
        window.requestAnimationFrame(() => {
          html.style.position = '';
          html.style.overflow = '';
          html.style.width = '';
          html.style.top = '';
          html.scrollTop = scrollTop;
        });
      };
    }
  }, [lock]);
};

export default useBodyScrollLock;
