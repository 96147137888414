import * as React from 'react';
import classes from './SwipeDownModal.scss';

export type Props = {
  visible: boolean;
  zIndex: number;
  onClose: () => void;
  contentContainerRef: React.MutableRefObject<HTMLElement | null>;
};

export const TopSpaceSize = 30;

// ## Note
// Contentをbottom: 0 CSS propertyを使って表示すると、
// 画面サイズが変わった時、特にステータスバーが表示された時に
// レイアウトの再計算が行われ、それがかなり大きな計算量になる。
// その結果、カクつくアニメーションになってしまうので、それを
// 回避するためにbottomではなくtopを0にして、offsetを
// 都度計算するようにしている
const SwipeDownModal: React.FC<Props> = React.memo(({ visible, zIndex, onClose, contentContainerRef, children }) => {
  // ブラウザの初期表示の状態がステータスバー表示状態なので、
  // その時のステータスバーなどを含まないViewの高さを取得する
  const [viewHeight, setViewHeight] = React.useState(window.innerHeight);

  const lastInnerHeight = React.useRef(window.innerHeight);

  React.useEffect(() => {
    // Safariの上部にユニバーサルリンクのアプリバナーが表示される場合、マウント時の viewHeight だとバナー領域の高さを含まない値になってしまうため、
    // window.innerHeight の変更を監視して、その最小値を viewHeight として使うようにする
    const handler = () => {
      // アプリバナーの高さが約62pxなので、innerHeightの変更差分がそれより小さい場合はviewHeightの変更を行わない（一旦仮で閾値を60pxとしている）
      if (Math.abs(lastInnerHeight.current - window.innerHeight) > 60) {
        setViewHeight(window.innerHeight);
      }
      lastInnerHeight.current = window.innerHeight;
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  // 内部コンテンツの高さ
  const [contentHeight, setContentHeight] = React.useState(0);
  React.useEffect(() => {
    if (!contentContainerRef.current) return;
    const dom = contentContainerRef.current;
    setContentHeight(dom.clientHeight);
  }, [children, contentContainerRef]);

  // 最初に画面外に表示されているようにとりあえず1000に設定している
  const [offsetY, setOffsetY] = React.useState(1000);

  // モーダルが表示された時のoffset
  // モーダルが戻ってくるoffsetでもある
  const initialOffsetY = React.useMemo(() => Math.max(viewHeight - TopSpaceSize - contentHeight, 0), [contentHeight, viewHeight]);

  React.useEffect(() => {
    if (visible) {
      setOffsetY(initialOffsetY);
    } else {
      // viewHeightはステータスバー表示中の高さなので
      // そのままではステータスバーが非表示になったときに
      // 下の方に見えてしまうので、
      // それを回避するために+200している
      setOffsetY(viewHeight + 200);
    }
  }, [visible, initialOffsetY, viewHeight]);

  // PullModeがtrueになるのは、
  // - モーダルが初期位置のときにタッチが始まり
  // - その次に下にタッチが移動したとき
  const [isPullMode, setIsPullMode] = React.useState<boolean | undefined>();
  const [lastTouchY, setLastTouchY] = React.useState<number | null>(null);
  const [lastTouchDelta, setLastTouchDelta] = React.useState(0);

  // タッチを開始したときにモーダルが一番上になければ
  // PullModeをオフにする
  const onTouchStart = React.useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (!contentContainerRef.current) return;
      const dom = contentContainerRef.current;

      setLastTouchY(event.touches[0].pageY);

      if (dom.scrollTop > 0) {
        // is scroll end?
        setIsPullMode(false);
      }
    },
    [contentContainerRef]
  );

  const onTouchMove = React.useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (lastTouchY === null) return; // unreachable
      if (!contentContainerRef.current) return;
      const dom = contentContainerRef.current;

      if (isPullMode === false) return;

      const currentY = event.touches[0].pageY;

      if (isPullMode === undefined) {
        if (currentY === lastTouchY) {
          return;
        } else if (currentY < lastTouchY) {
          setIsPullMode(false);
          return;
        } else {
          setIsPullMode(true);
          // returnせずに下の処理に進む
        }
      }

      dom.style.overflowY = 'hidden';
      const delta = currentY - lastTouchY;
      const newOffsetY = Math.max(offsetY + delta, 0);

      setOffsetY(newOffsetY);
      setLastTouchY(currentY);
      setLastTouchDelta(delta);
    },
    [lastTouchY, isPullMode, offsetY, contentContainerRef]
  );

  const onTouchEnd = React.useCallback(() => {
    if (!contentContainerRef.current) return;
    const dom = contentContainerRef.current;

    if (
      lastTouchDelta > 5 || // 下にスワイプしているとき
      (Math.abs(lastTouchDelta) < 5 && offsetY - initialOffsetY > contentHeight / 2) // 半分以上下げているとき
    ) {
      onClose();
    } else {
      setOffsetY(initialOffsetY);
    }

    // 諸々初期化
    dom.style.overflowY = 'scroll';
    setLastTouchY(null);
    setIsPullMode(undefined);
    setLastTouchDelta(0);
  }, [lastTouchDelta, offsetY, initialOffsetY, contentHeight, onClose, contentContainerRef]);

  // 各種スタイル
  const containerStyle = React.useMemo(
    () => ({
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      ['--offsetY' as any]: `${offsetY}px`,
      zIndex,
    }),
    [offsetY, zIndex]
  );

  const containerClassNames = [classes['container']];
  if (lastTouchY !== null) {
    containerClassNames.push(classes['touching']);
  }
  const containerClassName = containerClassNames.join(' ');

  return (
    <div className={containerClassName} style={containerStyle} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className={classes['top-space']} onClick={onClose} />
      {children}
    </div>
  );
});

SwipeDownModal.displayName = 'SwipeDownModal';

type ContentContainerProps = {
  // 内部のコンテンツが、表示可能領域よりも小さい場合にも
  // モーダルを高さいっぱいに表示する。
  // デフォルトでは、モーダル上部にスペースができる。
  full?: boolean;
};

export const ContentContainer = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ContentContainerProps>>(({ full, children }, ref) => {
  const classNames = [classes['content']];
  if (full) classNames.push(classes['full']);
  const className = classNames.join(' ');

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
});

ContentContainer.displayName = 'ContentContainer';

export default SwipeDownModal;
