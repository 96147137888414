import * as React from 'react';

import useBodyScrollLock from 'libs/useBodyScrollLock';
import SwipeDownModal, { ContentContainer } from 'components/molecules/SwipeDownModal';
import SceneRelationshipSelector from 'components/combi-search/sp/components/SceneRelationshipSelector';
import type { Scene, Relationship } from 'components/combi-search/types';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (scene: Scene | null, relationship: Relationship | null) => void;
  zIndex: number;
};

const SceneRelationshipSelectModal: React.FC<Props> = React.memo(({ show, onClose, onSelect, zIndex }) => {
  const [scene, setScene] = React.useState<Scene | null>(null);
  const [relationship, setRelationship] = React.useState<Relationship | null>(null);

  const contentContainerRef = React.useRef<HTMLDivElement | null>(null);

  const onInternalSelect = React.useCallback((scene, relationship) => {
    setScene(scene);
    setRelationship(relationship);
  }, []);

  const onSubmit = React.useCallback(() => {
    onSelect(scene, relationship);
  }, [onSelect, scene, relationship]);

  useBodyScrollLock(show);

  return (
    <SwipeDownModal visible={show} zIndex={zIndex} onClose={onClose} contentContainerRef={contentContainerRef}>
      <ContentContainer ref={contentContainerRef}>
        <SceneRelationshipSelector selectedScene={scene} selectedRelationship={relationship} onSelect={onInternalSelect} onSubmit={onSubmit} submitButtonText="保存する" />
      </ContentContainer>
    </SwipeDownModal>
  );
});

SceneRelationshipSelectModal.displayName = 'SceneRelationshipSelectModal';

export default SceneRelationshipSelectModal;
