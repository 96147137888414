import * as React from 'react';
import classes from './index.scss';

export type Props = {
  visible: boolean;
  onClick: () => void;
};

const BlackFilter: React.FC<Props> = React.memo(({ visible, onClick }) => {
  const classNames = [classes['black-filter']];
  if (visible) classNames.push(classes['visible']);
  const className = classNames.join(' ');

  return <div className={className} onClick={onClick} />;
});

BlackFilter.displayName = 'BlackFilter';

export default BlackFilter;
