import * as React from 'react';
import classes from './Carousel.scss';

export type Props = {
  idx: number;
  onChange: (idx: number) => void;
};

export const Carousel: React.FC<Props> = React.memo(({ idx, onChange, children }) => {
  // スライドのコンテンツ
  const nodes: React.ReactNode[] = isReactNodeArray(children) ? children : [children];
  const numNodes = nodes.length;

  // スクロール量の計算に必要
  const vw = React.useMemo(() => window.document.documentElement.clientWidth, []);

  const windowRef = React.useRef<null | HTMLDivElement>(null);
  const lastTouchRef = React.useRef<null | { time: number; left: number }>(null);
  const swipeSpeedRef = React.useRef(0);
  const cancelHandlerRef = React.useRef<CancelHandler>();

  // 自動スクロールを行う関数
  const scrollTo = React.useCallback(
    (idx: number, startTime: number) => {
      // 前のスクロールをキャンセル
      // （前のスクロールがない場合は何も起こらない）
      cancelHandlerRef.current?.cancel();

      if (!windowRef.current) return;
      const windowElem = windowRef.current;

      // 自動スクロールを開始
      const initialSpeed = swipeSpeedRef.current;
      swipeSpeedRef.current = 0;
      const cancelHandler = scrollLeft(windowElem, initialSpeed, vw * idx, startTime);
      cancelHandlerRef.current = cancelHandler;
    },
    [vw]
  );

  // idx propsが変更されたときにスクロールする
  React.useEffect(() => {
    scrollTo(idx, window.performance.now());
  }, [scrollTo, idx]);

  const onTouchStart = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    lastTouchRef.current = {
      time: window.performance.now(),
      left: e.touches[0].pageX,
    };
    swipeSpeedRef.current = 0;
    e.stopPropagation();
  }, []);

  // swipeSpeedを計算する
  const onTouchMove = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    if (!windowRef.current) return;
    if (!lastTouchRef.current) return;

    const { time, left } = lastTouchRef.current;

    const newTime = window.performance.now();
    const newLeft = e.touches[0].pageX;

    swipeSpeedRef.current = (newLeft - left) / (newTime - time);

    lastTouchRef.current = { time: newTime, left: newLeft };
  }, []);

  // scrollを行うかを判定する
  const onTouchEnd = React.useCallback(() => {
    if (!windowRef.current) return;
    const swipeSpeed = swipeSpeedRef.current;

    const startTime = lastTouchRef.current!.time;
    lastTouchRef.current = null;

    const startLeft = vw * idx;
    const lastLeft = windowRef.current.scrollLeft;

    if (swipeSpeed < -0.5) {
      // 左にスワイプしていたら次のpageに進む
      scrollTo((idx + 1) % numNodes, startTime);
      onChange((idx + 1) % numNodes);
    } else if (swipeSpeed > 0.5) {
      // 右にスワイプしていたら前のpageに戻る
      scrollTo((idx - 1 + numNodes) % numNodes, startTime);
      onChange((idx - 1 + numNodes) % numNodes);
    } else if (lastLeft > startLeft + vw / 2) {
      // 半分以上次のpageが見えていたら次のpageに進む
      scrollTo((idx + 1) % numNodes, startTime);
      onChange((idx + 1) % numNodes);
    } else if (lastLeft < startLeft - vw / 2) {
      // 半分以上前のpageが見えていたら前のpageに戻る
      scrollTo((idx - 1 + numNodes) % numNodes, startTime);
      onChange((idx - 1 + numNodes) % numNodes);
    } else {
      // 元の位置に戻す
      scrollTo(idx, startTime);
    }
  }, [idx, onChange, numNodes, scrollTo, vw]);

  // 1. windowRefに代入
  // 2. 縦スクロールを禁止するlistenerを追加
  const windowCallbackRef = React.useCallback((elem: HTMLDivElement | null) => {
    windowRef.current = elem;

    if (elem) {
      let scrollDir: 'v' | 'h' | null = null;
      const touchStart = { x: 0, y: 0 };
      let lastTouchX = 0;

      elem.addEventListener('touchstart', (e: TouchEvent) => {
        touchStart.x = e.touches[0].pageX;
        touchStart.y = e.touches[0].pageY;
        lastTouchX = e.touches[0].pageX;
        scrollDir = null;
      });

      // 横方向にスクロールを開始したときは、
      // 横方向にしかいかないようにする。
      // 縦方向にスクロールを開始したときは、
      // 縦方向にしかいかないようにする。
      elem.addEventListener(
        'touchmove',
        (e: TouchEvent) => {
          if (scrollDir === null) {
            const xmove = Math.abs(touchStart.x - e.touches[0].pageX);
            const ymove = Math.abs(touchStart.y - e.touches[0].pageY);
            if (xmove >= ymove) {
              scrollDir = 'h';
            } else {
              scrollDir = 'v';
            }
          }
          if (scrollDir === 'h') {
            e.preventDefault();
            elem.scrollLeft += lastTouchX - e.touches[0].pageX;
            lastTouchX = e.touches[0].pageX;
          }
        },
        false
      );
    }
  }, []);

  return (
    <div className={classes['window']} ref={windowCallbackRef} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className={classes['pages']}>
        {nodes.map((node, i) => (
          <div key={i} className={classes['page']}>
            {node}
          </div>
        ))}
      </div>
    </div>
  );
});
Carousel.displayName = 'Carousel';

const isReactNodeArray = (node: React.ReactNode): node is React.ReactNode[] => Array.isArray(node);

const scrollLeft = (element: Element, initialSpeed: number, targetLeft: number, startTime: number): CancelHandler => {
  const initialDistance = Math.abs(element.scrollLeft - targetLeft);
  if (initialDistance === 0) {
    return new CancelHandler();
  }

  const startTheta = computeTheta(Math.abs(initialSpeed), initialDistance);
  const speedX = computeSpeedX(initialDistance, startTheta);

  const cancelHandler = new CancelHandler();

  tickMoveLeft(element, targetLeft, startTime, startTheta, speedX, cancelHandler);

  return cancelHandler;
};

// 1frameでの変化
const tickMoveLeft = (element: Element, targetLeft: number, lastMoveTime: number, lastTheta: number, speedX: number, cancelHandler: CancelHandler) => {
  // キャンセル済みかチェック
  if (cancelHandler.isCanceled) {
    return;
  }

  const now = window.performance.now(); // ms
  const elapsed = now - lastMoveTime;
  const newTheta = Math.min(Math.PI, lastTheta + elapsed * angulerV);

  // targetまでの距離
  const distance = (1 + Math.cos(newTheta)) * speedX;
  moveElementAtDistanceFromTarget(element, targetLeft, distance);

  if (newTheta === Math.PI) {
    return;
  }

  window.requestAnimationFrame(() => tickMoveLeft(element, targetLeft, now, newTheta, speedX, cancelHandler));
};

// targetからdistance分だけ離れた近い方の位置に移動させる
const moveElementAtDistanceFromTarget = (element: Element, target: number, distance: number) => {
  if (element.scrollLeft < target) {
    element.scrollLeft = target - distance;
  } else {
    element.scrollLeft = target + distance;
  }
};

// 現在のspeedと、残りの距離から、現在の角度を出す
// この角度は、次のspeedを決定するのに使われる
// scrollは、角速度一定で進む
//
// return : radiun ( 0 < theta < pi )
//
// これは、
// speed = sin(theta) * speedX
// remain = (1 + cos(theta)) * speedX
// という連立方程式を解くと導ける.
const computeTheta = (speed: number, remain: number): number => {
  if (speed === 0) {
    return 0;
  } else {
    return 2 * Math.asin(speed / Math.sqrt(speed * speed + remain * remain));
  }
};

// 速度係数を出す
//
// 速度係数は、角速度を速度に変換するために使われる
// v = sin(theta) * speedX
//
// これは、残りの移動距離と、現在の角度から求められる.
// （速度を積分する）
// remain = (1 + cos(theta)) * speedX
const computeSpeedX = (remain: number, theta: number): number => remain / (1 + Math.cos(theta));

// 角速度 (rad / ms)
const angulerV = Math.PI / 468;

class CancelHandler {
  isCanceled: boolean;

  constructor() {
    this.isCanceled = false;
  }

  cancel() {
    this.isCanceled = true;
  }
}
