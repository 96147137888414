import * as React from 'react';

import * as color from 'libs/colors';
import { merge } from 'libs/classname';
import { genPublicUrl } from 'libs/cloudinary';
import { ChevronRightIcon, ChevronDownIcon } from 'components/icons';
import Paragraph from 'components/atoms/Paragraph';
import Text from 'components/atoms/Text';
import Divider from 'components/atoms/Divider';
import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import LazyImage from 'components/atoms/LazyImage';
import TopBar from 'components/molecules/TopBar';

import cs from './index.scss';
import { CombiSearchContext } from 'components/combi-search/Context';
import { SelectedCategory, ParentCategory, ChildCategory, ProductGroup } from 'components/combi-search/types';

export type Props = {
  selected: SelectedCategory | null;
  onSelect: (selected: SelectedCategory | null) => void;
};

const CategorySelector: React.FC<Props> = React.memo(({ selected, onSelect }) => {
  const { parents } = React.useContext(CombiSearchContext);

  const onUnselectClick = React.useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  return (
    <Box bg={color.white}>
      <TopBar>
        <Paragraph align="center" size="18px" bold color={color.black}>
          カテゴリ
        </Paragraph>
      </TopBar>
      <Divider />
      {selected && (
        <>
          <CurrentSelectBar selected={selected} />
          <Divider />
        </>
      )}
      <UnselectRow onClick={onUnselectClick} />
      <Divider />
      {parents.map((parent) => (
        <React.Fragment key={parent.name}>
          <ParentCategorySection parent={parent} onSelect={onSelect} />
          <Divider />
        </React.Fragment>
      ))}
      <Spacer size={60} />
    </Box>
  );
});

CategorySelector.displayName = 'CategorySelector';

/*
 * =================
 * CurrentSelectBar
 * =================
 */
export type CurrentSelectBarProps = {
  selected: SelectedCategory;
};

const CurrentSelectBar: React.FC<CurrentSelectBarProps> = ({ selected }) => (
  <Box bg={color.gray10} padding="19px 16px">
    {selected.category === null ? (
      <Paragraph align="left" size="12px">
        <Text color={color.softOrange}>{selected.parent.name}</Text>
        <Text color={color.gray50}> を選択中</Text>
      </Paragraph>
    ) : (
      <Paragraph align="left" size="12px">
        <Text color={color.gray50}>{selected.parent.name}</Text>
        <Text color={color.gray50}> &gt; </Text>
        <Text color={color.gray50}>{selected.category.name}</Text>
        <Text color={color.gray50}> &gt; </Text>
        <Text color={color.softOrange}>{selected.productGroup?.name || `全ての${selected.category.name}`}</Text>
        <Text color={color.gray50}> を選択中</Text>
      </Paragraph>
    )}
  </Box>
);

/*
 * ==============
 * UnselectRow
 * ==============
 */
export type UnselectRowProps = {
  onClick: () => void;
};

const UnselectRow: React.FC<UnselectRowProps> = ({ onClick }) => (
  <div className={cs['unselect-row']} onClick={onClick}>
    <div className={cs['unselect-row-title']}>指定なし</div>
    <ChevronRightIcon color={color.gray50} />
  </div>
);

/*
 * ====================
 * ParentCategorySection
 * ====================
 */
export type ParentCategorySectionProps = {
  parent: ParentCategory;
  onSelect: (select: SelectedCategory) => void;
};

const ParentCategorySection: React.FC<ParentCategorySectionProps> = ({ parent, onSelect }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onSelectAllChild = React.useCallback(() => {
    onSelect({ parent, category: null, productGroup: null });
  }, [parent, onSelect]);

  return (
    <div>
      <div className={merge(cs['parent-category-row'], isOpen ? cs['highlight'] : '')} onClick={toggleIsOpen}>
        <ParentCategoryIcon src={genPublicUrl(parent.imageUrl, 26 * 4)} />
        <div className={cs['parent-category-name']}>{parent.name}</div>
        <ChevronDownIcon color={color.gray50} className={isOpen ? cs['icon-up'] : cs['icon-down']} />
      </div>
      {isOpen && (
        <div>
          <div className={cs['child-category-row']} onClick={onSelectAllChild}>
            <div className={cs['child-category-name']}>{`すべての${parent.name}`}</div>
            <ChevronRightIcon color={color.gray50} />
          </div>
          {parent.categories.map((childCategory) => (
            <ChildCategorySection
              key={childCategory.name}
              category={childCategory}
              onSelectProductGroup={(productGroup) =>
                onSelect({
                  parent,
                  category: childCategory,
                  productGroup,
                })
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

type ParentCategoryIconProps = {
  src: string;
};

const ParentCategoryIcon: React.FC<ParentCategoryIconProps> = React.memo(({ src }) => (
  <div className={cs['parent-category-icon']}>
    <LazyImage src={src} width="26px" height="26px" fit="contain" />
  </div>
));

ParentCategoryIcon.displayName = 'ParentCategoryIcon';

/*
 * =====================
 * ChildCategorySection
 * =====================
 */
export type ChildCategorySectionProps = {
  category: ChildCategory;
  onSelectProductGroup: (productGroup: ProductGroup | null) => void;
};

const ChildCategorySection: React.FC<ChildCategorySectionProps> = ({ category, onSelectProductGroup }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      {/* ChildCategoryHeader */}
      <div className={cs['child-category-row']} onClick={() => setIsOpen(!isOpen)}>
        <div className={cs['child-category-name']}>{category.name}</div>
        <ChevronDownIcon color={color.gray50} className={isOpen ? cs['icon-up'] : cs['icon-down']} />
      </div>

      {/* ProductCategoryList */}
      {isOpen && (
        <div className={cs['product-group-list']}>
          <div className={cs['product-group-row']} onClick={() => onSelectProductGroup(null)}>
            <div className={cs['product-group-name']}>{`すべての${category.name}`}</div>
            <ChevronRightIcon color={color.gray50} />
          </div>
          {category.productGroups.map((group) => (
            <div key={group.name} className={cs['product-group-row']} onClick={() => onSelectProductGroup(group)}>
              <div className={cs['product-group-name']}>{group.name}</div>
              <ChevronRightIcon color={color.gray50} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CategorySelector;
