import * as React from 'react';

import useBodyScrollLock from 'libs/useBodyScrollLock';
import SwipeDownModal, { TopSpaceSize } from 'components/molecules/SwipeDownModal';
import HorizontalViewSwitcher from 'components/molecules/HorizontalViewSwitcher';

import DetailSelector from 'components/combi-search/sp/components/DetailSelector';
import AgeSelector from 'components/combi-search/sp/components/AgeSelector';
import DeliverySelector from 'components/combi-search/sp/components/DeliverySelector';
import type { Detail } from 'components/combi-search/types';
import useMountedTime from 'libs/useMountedTime';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (detail: Detail) => void;
  zIndex: number;
};

const DetailSelectModal: React.FC<Props> = React.memo(({ show, onClose, onSelect, zIndex }) => {
  const [selected, setSelected] = React.useState<Detail>(emptyDetail);
  const [showAgeSelector, setShowAgeSelector] = React.useState(false);
  const [showDeliverySelector, setShowDeliverySelector] = React.useState(false);

  const contentContainerRef = React.useRef<HTMLDivElement | null>(null);

  useBodyScrollLock(show);

  const showDetailSelector = !showAgeSelector && !showDeliverySelector;

  const onSelectDeliverySelector = React.useCallback((delivery) => {
    setSelected((prev) => ({ ...prev, delivery }));
    setShowDeliverySelector(false);
  }, []);

  // このコンポーネントがマウントされたときの時刻を取得する。マウントされる前はnullを取得しコンポーネントを表示しない。
  // DeliverySelectorは事前ビルドされないコンポーネントであるが、その知識が暗黙的になっていた。
  // 以前は平然と`new Date()`を使用していたが、もし事前ビルドされていたらビルド時点での時刻を使用することになる。
  // ここで明示的にnull時に表示しないことで「事前ビルド時にはレンダリングしない」という知識を明示的にする。
  const mountedTime = useMountedTime();

  return (
    <SwipeDownModal visible={show} zIndex={zIndex} onClose={onClose} contentContainerRef={contentContainerRef}>
      <HorizontalViewSwitcher smooth height={`calc(var(--vh) * 100 - ${TopSpaceSize}px)`} currentViewIdx={showDetailSelector ? 0 : 1} contentContainerRef={showDetailSelector ? contentContainerRef : undefined}>
        <DetailSelector
          key="detail"
          selected={selected}
          onSelect={setSelected}
          onSubmit={() => onSelect(selected)}
          onClickAgeSelector={React.useCallback(() => {
            setShowAgeSelector(true);
          }, [])}
          onClickDeliverySelector={React.useCallback(() => {
            setShowDeliverySelector(true);
          }, [])}
          submitButtonText="保存する"
        />
        <HorizontalViewSwitcher height={`calc(var(--vh) * 100 - ${TopSpaceSize}px)`} currentViewIdx={showAgeSelector ? 0 : 1} contentContainerRef={showDetailSelector ? undefined : contentContainerRef}>
          <AgeSelector
            key="age"
            selected={selected.age}
            onSelect={React.useCallback((age) => {
              setSelected((prev) => ({ ...prev, age }));
              setShowAgeSelector(false);
            }, [])}
            onClose={React.useCallback(() => {
              setShowAgeSelector(false);
            }, [])}
          />
          {mountedTime ? <DeliverySelector key="delivery" selected={selected.delivery} now={mountedTime} onSelect={onSelectDeliverySelector} /> : null}
        </HorizontalViewSwitcher>
      </HorizontalViewSwitcher>
    </SwipeDownModal>
  );
});

DetailSelectModal.displayName = 'DetailSelectModal';

const emptyDetail = {
  keyword: '',
  delivery: null,
  express: false,
  age: null,
  subOptionCategories: [],
  coupons: [],
  includeOutOfStock: false,
  isTanpProducts: false,
  onlyIncludeEGiftProducts: false,
};

export default DetailSelectModal;
