import * as React from 'react';

import * as colors from 'libs/colors';
import { ChevronRightIcon, HelpCircle } from 'components/icons';
import Paragraph from 'components/atoms/Paragraph';
import Text from 'components/atoms/Text';
import CheckBox from 'components/atoms/CheckBox';
import Divider from 'components/atoms/Divider';
import Spacer from 'components/atoms/Spacer';
import Box, { BoxItem } from 'components/atoms/Box';
import TopBar from 'components/molecules/TopBar';
import SearchInput from 'components/molecules/SearchInput';

import { Age, DeliveryDate, Detail, Coupon, SubOptionCategory } from 'components/combi-search/types';
import { emptySearchConditions } from 'components/combi-search/libs/search';
import { CombiSearchContext } from 'components/combi-search/Context';
import Footer from '../Footer';
import cs from './index.scss';
import OutsideClickHandler from 'components/atoms/OutsideClickHandler';

export type Props = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
  onSubmit: () => void;
  onClickAgeSelector: () => void;
  onClickDeliverySelector: () => void;
  submitButtonText: string;
};

const DetailSelector: React.FC<Props> = React.memo(({ selected, onSelect, onSubmit, onClickAgeSelector, onClickDeliverySelector, submitButtonText }) => {
  const { hasteShippingTime, coupons, subOptionCategories } = React.useContext(CombiSearchContext);

  const now = React.useMemo(() => new Date(), []);

  const showHasteShippingNote = hasteShippingTime.start.valueOf() < now.valueOf() && now.valueOf() < hasteShippingTime.end.valueOf();

  return (
    <Box bg={colors.white}>
      <TopBar>
        <Paragraph align="center" size="18px" bold color={colors.black}>
          こだわり
        </Paragraph>
      </TopBar>
      <Divider />
      <KeywordSection
        value={selected.keyword}
        onChange={React.useCallback(
          (keyword) =>
            onSelect({
              ...selected,
              keyword,
            }),
          [onSelect, selected]
        )}
      />
      <Divider />
      <AgeSection age={selected.age} onClick={onClickAgeSelector} />
      <Divider />
      <DeliverySection delivery={selected.delivery} showHasteShippingNote={showHasteShippingNote} onClick={onClickDeliverySelector} />
      <Divider />
      <SubOptionSection
        available={subOptionCategories}
        selected={selected.subOptionCategories}
        onChange={React.useCallback(
          (subOptionCategories) =>
            onSelect({
              ...selected,
              subOptionCategories,
            }),
          [onSelect, selected]
        )}
      />
      <Divider />
      <CouponSection available={coupons} selected={selected.coupons} onChange={React.useCallback((coupons) => onSelect({ ...selected, coupons }), [onSelect, selected])} />
      <Divider />
      <ExtraOptionSection selected={selected} onSelect={onSelect} />
      <Footer
        submitButtonText={submitButtonText}
        clearButtonText="クリア"
        onClear={React.useCallback(() => {
          onSelect(emptySearchConditions.detail);
        }, [onSelect])}
        onSubmit={onSubmit}
      />
    </Box>
  );
});

DetailSelector.displayName = 'DetailSelector';

/*
 * ===============
 * KeywordSection
 * ===============
 */
type KeywordSectionProps = {
  value: string;
  onChange: (keyword: string) => void;
};

const KeywordSection: React.FC<KeywordSectionProps> = React.memo(({ value, onChange }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" size="14px" color={colors.gray90} bold>
        キーワード
      </Paragraph>
    </SectionHeadlineBox>
    <Divider />
    <Box padding="16px" bg={colors.white}>
      <SearchInput placeholder="キーワード・商品名で検索" value={value} onChange={onChange} />
    </Box>
  </>
));

KeywordSection.displayName = 'KeywordSection';

/*
 * ===============
 * AgeSection
 * ===============
 */
type AgeSectionProps = {
  age: Age | null;
  onClick: () => void;
};

const AgeSection: React.FC<AgeSectionProps> = React.memo(({ age, onClick }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" size="14px" color={colors.gray90} bold>
        年代
      </Paragraph>
    </SectionHeadlineBox>
    <Divider />
    <Box padding="16px 22px 16px 16px" bg={colors.white}>
      <Box horizontal align="center" onClick={onClick}>
        <BoxItem scale={1}>
          <Paragraph align="left" size="14px" color={age ? colors.black : colors.gray30}>
            {age?.name || '指定なし'}
          </Paragraph>
        </BoxItem>
        <ChevronRightIcon color={colors.gray50} />
      </Box>
    </Box>
  </>
));

AgeSection.displayName = 'AgeSection';

/*
 * ================
 * DeliverySection
 * ================
 */
type DeliverySectionProps = {
  delivery: DeliveryDate | null;
  showHasteShippingNote: boolean;
  onClick: () => void;
};

const DeliverySection: React.FC<DeliverySectionProps> = React.memo(({ delivery, showHasteShippingNote, onClick }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" color={colors.gray90} bold>
        <Text size="14px">お届け日</Text>
        <Text size="12px">（最も遅くていつまでに届けたいか）</Text>
      </Paragraph>
      <Spacer size="4px" />
      {showHasteShippingNote && (
        <Paragraph align="left" size="11px" color={colors.gray50}>
          ※お急ぎ便（＋390円）なら<Text color={colors.softRed}>最短翌日</Text>
          発送から可能です
        </Paragraph>
      )}
    </SectionHeadlineBox>
    <Divider />
    <Box padding="16px 22px 16px 16px" bg={colors.white}>
      <Box horizontal align="center" onClick={onClick}>
        <BoxItem scale={1}>
          <Paragraph align="left" size="14px" color={delivery ? colors.black : colors.gray30}>
            {deliveryDateToString(delivery)}
          </Paragraph>
        </BoxItem>
        <ChevronRightIcon color={colors.gray50} />
      </Box>
    </Box>
  </>
));

const deliveryDateToString = (delivery: DeliveryDate | null): string => {
  if (delivery === null) {
    return '指定しない';
  } else {
    const { date, prefecture } = delivery;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `〜${year}/${month}/${day}（${prefecture.name}）`;
  }
};

DeliverySection.displayName = 'DeliverySection';

/*
 * =================
 * SubOptionSection
 * =================
 */
type SubOptionSectionProps = {
  available: SubOptionCategory[];
  selected: SubOptionCategory[];
  onChange: (selected: SubOptionCategory[]) => void;
};

const SubOptionSection: React.FC<SubOptionSectionProps> = React.memo(({ available, selected, onChange }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" size="14px" color={colors.gray90} bold>
        ギフトオプション
      </Paragraph>
    </SectionHeadlineBox>
    <div className={cs['suboption-checkbox-container']}>
      {available.map((subOptionCategory, i) => (
        <React.Fragment key={subOptionCategory.id}>
          {i % 2 === 0 && <Divider />}
          <div className={cs['suboption-checkbox-cell']}>
            <Box horizontal bg={colors.white} padding="16px 20px 16px 16px">
              <BoxItem scale={1}>
                <CheckBoxItem
                  label={subOptionCategory.name}
                  checked={selected.some((o) => o.id === subOptionCategory.id)}
                  onChange={(checked) => {
                    const newSelected = checked ? selected.concat([subOptionCategory]) : selected.filter((o) => o.id !== subOptionCategory.id);
                    onChange(newSelected);
                  }}
                />
              </BoxItem>
            </Box>
          </div>
        </React.Fragment>
      ))}
    </div>
  </>
));

SubOptionSection.displayName = 'SubOptionSection';

/*
 * ===============
 * CouponSection
 * ===============
 */
type CouponSectionProps = {
  available: Coupon[];
  selected: Coupon[];
  onChange: (selected: Coupon[]) => void;
};

const CouponSection: React.FC<CouponSectionProps> = React.memo(({ available, selected, onChange }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" size="14px" color={colors.gray90} bold>
        クーポン
      </Paragraph>
    </SectionHeadlineBox>
    <Divider />
    <Box padding="18px" bg={colors.white}>
      {available.map((coupon, i) => (
        <React.Fragment key={coupon.name}>
          {i !== 0 && <Spacer size={16} />}
          <CheckBoxItem
            label={coupon.name}
            checked={selected.some((c) => c.id === coupon.id)}
            onChange={(checked) => {
              const newSelected = checked ? selected.concat([coupon]) : selected.filter((c) => c.id !== coupon.id);
              onChange(newSelected);
            }}
          />
        </React.Fragment>
      ))}
    </Box>
  </>
));

CouponSection.displayName = 'CouponSection';

/*
 * ===================
 * ExtraOptionSection
 * ===================
 */
type ExtraOptionSectionProps = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
};

const ExtraOptionSection: React.FC<ExtraOptionSectionProps> = React.memo(({ selected, onSelect }) => (
  <>
    <SectionHeadlineBox>
      <Paragraph align="left" size="14px" color={colors.gray90} bold>
        対象商品
      </Paragraph>
    </SectionHeadlineBox>
    <Divider />
    <Box padding="18px" bg={colors.white}>
      <CheckBoxItem label="eギフト対象商品のみ表示" checked={selected.onlyIncludeEGiftProducts} onChange={React.useCallback((onlyIncludeEGiftProducts) => onSelect({ ...selected, onlyIncludeEGiftProducts }), [onSelect, selected])} />
      <Spacer size={16} />
      <CheckBoxItem label="在庫切れ商品も表示" checked={selected.includeOutOfStock} onChange={React.useCallback((includeOutOfStock) => onSelect({ ...selected, includeOutOfStock }), [onSelect, selected])} />
      <Spacer size={16} />
      <TanpProductsOnlyCheckbox selected={selected} onSelect={onSelect} />
    </Box>
  </>
));

ExtraOptionSection.displayName = 'ExtraOptionSection';

/*
 * =========================
 * TanpProductsOnlyCheckbox
 * =========================
 */
type TanpProductsOnlyCheckbox = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
};

const TanpProductsOnlyCheckbox: React.FC<TanpProductsOnlyCheckbox> = React.memo(({ selected, onSelect }) => {
  const [showHelpDialogue, setShowHelpDialogue] = React.useState<boolean>(false);
  const openHelpDialogue = React.useCallback(() => {
    setShowHelpDialogue(true);
  }, []);
  const closeHelpDialogue = React.useCallback(() => {
    setShowHelpDialogue(false);
  }, []);

  return (
    <div className={cs['extra-option']}>
      <Box horizontal align="center">
        {showHelpDialogue ? (
          <OutsideClickHandler onClickOutside={closeHelpDialogue}>
            <div className={cs['extra-option-bubble-box']}>
              <div className={cs['extra-option-bubble-card']}>
                <p className={cs['extra-option-bubble-text']}>タンプ倉庫から発送する商品で、多彩なオプションがお選び頂けます。なお、発送元が同一の商品に対して、注文個数に応じた追加の送料がかかることはございません。</p>
              </div>
            </div>
          </OutsideClickHandler>
        ) : null}
        <CheckBoxItem label="タンプからの発送商品のみ表示" checked={selected.isTanpProducts} onChange={React.useCallback((isTanpProducts) => onSelect({ ...selected, isTanpProducts }), [onSelect, selected])} />
        <div
          className={cs['extra-option-help-circle']}
          onClick={(e) => {
            e.stopPropagation;
            openHelpDialogue();
          }}
        >
          <HelpCircle size="14.5px" color={colors.gray50} />
        </div>
      </Box>
    </div>
  );
});

TanpProductsOnlyCheckbox.displayName = 'TanpProductsOnlyCheckbox';

/*
 * ===============
 * OptionCheckBox
 * ===============
 */
type CheckBoxItemProps = {
  label: string;
  checked: boolean;
  onChange: (val: boolean) => void;
};

const CheckBoxItem: React.FC<CheckBoxItemProps> = ({ label, checked, onChange }) => (
  <label>
    <Box horizontal>
      <CheckBox checked={checked} onChange={onChange} />
      <Spacer size={10} />
      <Paragraph align="center" size="14px" color={colors.gray90}>
        {label}
      </Paragraph>
    </Box>
  </label>
);

/*
 * ================
 * SectionHeadlineBox
 * ================
 */
const SectionHeadlineBox: React.FC = ({ children }) => (
  <Box padding="12px 16px 8px" bg={colors.gray10}>
    {children}
  </Box>
);

export default DetailSelector;
