import * as React from 'react';

import cs from './index.scss';

export type Props = {
  submitButtonText: string;
  clearButtonText: string;
  opaque?: boolean;
  onSubmit: () => void;
  onClear: () => void;
  className?: string;
};

const Footer: React.FC<Props> = React.memo(({ submitButtonText, clearButtonText, opaque, onSubmit, onClear, className }) => {
  const containerClassNames = [cs['container']];
  if (opaque) {
    containerClassNames.push(cs['opaque']);
  }
  if (className) {
    containerClassNames.push(className);
  }

  return (
    <div className={containerClassNames.join(' ')}>
      <button className={cs['clear-btn']} onClick={onClear}>
        {clearButtonText}
      </button>
      <button className={cs['submit-btn']} onClick={onSubmit}>
        {submitButtonText}
      </button>
    </div>
  );
});

Footer.displayName = 'Footer';

export default Footer;
