import * as React from 'react';

import * as colors from 'libs/colors';
import { merge } from 'libs/classname';
import { MapPinIcon } from 'components/icons';
import Spacer from 'components/atoms/Spacer';
import Divider from 'components/atoms/Divider';
import Select from 'components/molecules/Select';

import { Prefecture } from 'components/combi-search/types';
import cs from './Header.scss';

export type Props = {
  prefectures: Prefecture[];
  selectedPrefecture: Prefecture | null;
  onSelectPrefecture: (selected: Prefecture) => void;
  hasteShippingEndHour: number | null;
  className?: string;
};

const Header: React.FC<Props> = React.memo(({ prefectures, selectedPrefecture, onSelectPrefecture, hasteShippingEndHour, className }) => {
  const selectItems = React.useMemo(
    () =>
      prefectures.map((prefecture) => ({
        key: prefecture.id.toString(),
        value: prefecture.name,
      })),
    [prefectures]
  );

  return (
    <div className={merge(cs['container'], className)}>
      <p className={cs['top-title']}>いつまでのお届けをご希望ですか？</p>
      <div className={cs['area-select-section']}>
        <MapPinIcon size="15px" color={colors.gray90} />
        <p className={cs['area-select-section-title']}>お届けする地域</p>
        <Select
          className={cs['area-select-section-selector']}
          items={selectItems}
          selected={selectedPrefecture?.id.toString() ?? null}
          onSelect={React.useCallback(
            (selected) => {
              const prefecture = prefectures.find(({ id }) => id.toString() === selected)!;
              onSelectPrefecture(prefecture);
            },
            [prefectures, onSelectPrefecture]
          )}
        />
      </div>
      {hasteShippingEndHour !== null ? (
        <p className={cs['express-delivery-notice']}>
          <span className={cs['attention']}>※</span>
          <span className={cs['bold']}>：お急ぎ便（＋390円）</span>
          で配送可能。{hasteShippingEndHour}時までにご決済下さい。
        </p>
      ) : null}
      <div className={cs['weekdays']}>
        <p className={cs['sunday']}>日</p>
        <p>月</p>
        <p>火</p>
        <p>水</p>
        <p>木</p>
        <p>金</p>
        <p className={cs['saturday']}>土</p>
      </div>
      <Spacer size="8px" />
      <Divider />
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
